#standingorder {
    font-family: "MyFont";
    border-spacing: 0;
    width: 100%;
    color: white;
    border-collapse: collapse;
    /* make the table borders collapse to each other */
    width: 100%;
}

#standingorder th {
    background: #000000;
}


#standingorder td {
    border-bottom: 5px solid #000000;
    white-space: nowrap;
}

/*
        #standingorder td:first-child {
            border-bottom-left-radius: 20px;
            border-bottom: 5px solid #000000;
        }*/

#standingorder tr {
    background-image: linear-gradient(97deg, rgba(10, 10, 10, 1) 0%, rgba(37, 37, 37, 1) 99%);
    align-items: center;
    padding-right: 20px;
}

#standingorder th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: white;
    padding-left: 20px;
}

.photopdf {
    height: 40px;
    width: 42px;
}

#btnstandingorder {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: transparent;
    /*    width: 50%;*/
    border-color: Turquoise;
    color: Turquoise;
}

.tableFixHeadnewoffer {
    height: 487px;
    /* gives an initial height of 200px to the table */
}

.table th:last-child,
.table td:last-child {
    padding-right: 10px;
}

.table tbody td,
.table th {
    border: none;
    padding: 10px 10px 10px 10px;
}

.qestionchild-div {
    position: absolute;
    bottom: 0;
    color: darkgray;
    text-align: center
}

.rowqestionchild-div {
    display: flex;
    justify-content: center;
}