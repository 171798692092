.pagination {
  display: flex;
  @include list-unstyled();
  @include border-radius();
}

.page-link {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 2;
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      @include border-left-radius($border-radius);
    }
  }
  &:last-child {
    .page-link {
      @include border-right-radius($border-radius);
    }
  }

  &.active .page-link {
    z-index: 1;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}



// ............................. EasyDev .......................

.pagination {
  display: flex;
  align-items: baseline;
}

.pagination__link {
  background: transparent;
  border: none;
  color: $color-additional;
  height: 28px;
  font-size: 10px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;

  @include directify($directions) {
    #{directed('margin-right')}: 2px;
  }

  &:hover {
    color: $color-accent;
    background: transparent;
  }

  &:focus, &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
  }

  &.pagination__link--arrow {
    border-radius: 50% !important;
    width: 28px;
    height: 28px;

    @include themify($themes) {
      background: themed('colorFieldsBorder');
    }

    &:hover {
      background: $color-accent;

      svg {
        fill: white;
      }
    }

    &:disabled {
      cursor: default;
      opacity: 0.4;

      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }

      svg {
        fill: $color-additional;
      }
    }
  }
}

.pagination__item {

  &.active .pagination__link {
    background-color: transparent;
    font-weight: bold;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &.disabled {

    .pagination__link svg {

      @include themify($themes) {
        fill: themed('colorIcon');
      }
    }
  }
}

.pagination__link-icon {
  width: 13px;
  height: 13px;
  fill: $color-additional;
}

.pagination__wrap {
  display: flex;
  margin-top: 15px;

  &.pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
}

.pagination-info, .pagination__select-form {
  color: $color-additional;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;

  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 10px;
  }

  select {

    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  }
}

.pagination__item-option {
  color: $color-additional;
  margin-bottom: 20px;
  font-size: 14px;

  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 10px;
  }
}