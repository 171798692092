// ======================================================
// Custom layout variables
// ======================================================
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

// ======================================================
// Theme Global setting colors
// ======================================================

$themecolor: #1e88e5;
$themecolor-dark: #028ee1;
$themecolor-alt: #26c6da;
$theme-light: #fff;
$theme-dark: #212529;

$skin1: #272c33;
$skin2: #00acc1;
$skin3: #fc4b6c;
$skin4: black;
$skin5: #7460ee;
$skin6: #fff;

// ======================================================
// Topbar settings
// ======================================================

$topbar: $theme-light;
$topbar-height: 55px;
$topbar-navlink-padding: 0px 15px;
$topbar-navlink-font-size: 19px;
$topbar-navlink-height: 30px;
$topbar-navbrand-padding: 0 10px 0 10px;
$topbar-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
$topbar-mailbox-width: 300px;
$topbar-mailbox-height: 317px;
$topbar-droptitle-bg: url(../../../../assets/images/background/img5.png) no-repeat;

// ======================================================
// Authentication backgrounds
// ======================================================

$errorbg: url(../../../../assets/images/background/error-bg.jpg) no-repeat center center #fff;

$profile-bg: url(../../../../assets/images/background/user-info.jpg);

// For Form Material
$danger-img: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='../../../../assets/images/icon/danger.svg'");
$warning-img: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='../../../../assets/images/icon/warning.svg'");
$success-img: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='../../../../assets/images/icon/success.svg'");

// ======================================================
// Sidebar settings
// ======================================================
// sidebar type
$sidebar-width-full: 240px;
$sidebar-width-iconbar: 180px;
// $sidebar-width-mini: 65px;
$sidebar-width-mini: 0px;
$left-part-width: 260px;

// sidebar text color settings
$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$sidebar-text-dark: #607d8b;
$sidebar-icons-dark: #99abb4;
$sidebar-iconshover: #000;


// ======================================================
// boxed layout setting
// ======================================================
$boxed-width: 1200px;

// ======================================================
// Extra style setting
// ======================================================
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
$transitions: 0.2s ease-in;
$transparent-dark-bg: rgba(0, 0, 0, 0.03);
$lft: left;
$rgt: right;
$card-alt: #e4e9ef;

// ======================================================
// Mixin setting
// ======================================================
%square {
  border-radius: 0px;
}

%rotate45 {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

%topbar-shadow {
  box-shadow: $topbar-shadow;
  -webkit-box-shadow: $topbar-shadow;
  -o-box-shadow: $topbar-shadow;
  -moz-box-shadow: $topbar-shadow;
}

%card-shadow {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
}

// ======================================================
// Bootstrap override colors
// ======================================================

$white: #fff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default;
$gray-700: #525f7f !default;
$gray-800: #263238 !default;
$gray-900: #455a64 !default;
$black: #000 !default;
$cyan: #4fc3f7 !default;

$blue: #1e88e5 !default;
$indigo: #9437ff !default;
$purple: #6f42c1 !default;
$red: #fc4b6c !default;
$orange: #fd7e13 !default;
$yellow: #ffb22b !default;
$green: #21c1d6 !default;
$greenlynko: #1EF1C6 !default;
$greenbutton: #80ffdb !default;
$goldbutton: #fd7e13 !default;
$newblackbutton: #555b6e !default;
$newbluebutton: #4fc3f7 !default;
$brightpurple: #9437ff !default;
$selected: #2c2c2c !default;
$redlynkco: #dc1860 !default;


$colors: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(("blue": $blue,
      "indigo": $indigo,
      "purple": $purple,
      "red": $red,
      "orange": $orange,
      "yellow": $yellow,
      "green": $green,
      "cyan": $cyan,
      "white": $white,
      "greenbutton": $greenbutton,
      "goldbutton": $goldbutton,
      "newblack": $newblackbutton,
      "newblue": $newbluebutton,
      "brightpurple": $brightpurple,
      "selected": $selected,
      "redlynkco": $redlynkco ),
    $colors
);

$primary: $indigo  !default;
$success: $greenlynko  !default;
$info: $goldbutton  !default;
$warning: $yellow  !default;
$danger: $red  !default;
$newgreen: $greenbutton;
$newblack: $newblackbutton;
$newblue: $newbluebutton  !default;
$orange: $orange  !default;
$brightpurple: $brightpurple  !default;

$theme-colors: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(("primary": $primary,
      "secondary": $gray-600,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "inverse": #263238,
      "light-danger": #ffebee,
      "light-info": #e1f5fe,
      "light-warning": #fffde7,
      "light-success": #f1f8e9,
      "newblack": $newblack,
      "newblue": $newblue,
      "brightpurple": $brightpurple,
      "selected": $selected,
      "redlynkco": $redlynkco ),
    $theme-colors
);

// ======================================================
// Dark Theme Colors Settings
// ======================================================
$theme-dark-bg-layout: #000;
$theme-dark-text-color: #b2b9bf !default;
$theme-dark-border-color: rgba(255, 255, 255, 0.2) !default;
$theme-dark-focus-border-color: #1EF1C6 !default;
$theme-dark-card-bg: rgba(0, 0, 0, 0.95);
$theme-dark-bg-light: #2b2b2b;
$main-body-bg-dark: $gray-900  !default;
$background-alt: #2b2b2b !default;
$background-selected: #2b2b2b !default;


// ======================================================
// Brand Colors
// ======================================================
$brand-colors: (
  ) !default;
$brand-colors: map-merge(("facebook": #3b5999,
      "twitter": #1da1f2,
      "googleplus": #dd4b39,
      "instagram": #e4405f,
      "pinterest": #bd081c,
      "youtube": #cd201f,
      "linkedin": #007bb6,
      "slack": #3aaf85,
      "dribbble": #ea4c89,
      "github": #222222,
    ),
    $brand-colors
);

// ======================================================
// Enable optional
// ======================================================
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;

// ======================================================
// Body
// ======================================================
$main-body-bg: #fff !default;
$body-bg: #eef5f9;
$body-color: #67757c !default;

// ======================================================
// Shadow setting
// ======================================================
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.08) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
// Style anchor elements.

$link-hover-decoration: none !default;

// ======================================================
// Typography
// ======================================================
$font-size-base: 0.875rem;
$font-family-sans-serif: "Poppins", sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-base: $font-weight-light;

// display setting
$display5-size: 3rem !default;
$display6-size: 2.5rem !default;
$display7-size: 2rem !default;
$display5-weight: 300 !default;
$display6-weight: 300 !default;
$display7-weight: 300 !default;

// heading fonts setting
$h1-font-size: 36px !default;
$h2-font-size: 74px !default;
$h3-font-size: 21px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;
$headings-margin-bottom: (
  1rem / 2) !default;
$headings-font-weight: 400 !default;
$headings-color: #455a64 !default;

// --------------------------------------------------------------------- //
//              Bootstrap Component override setting
// --------------------------------------------------------------------- //

// Breadcrumbs
$breadcrumb-bg: $body-bg;
$breadcrumb-margin-bottom: 1.5rem;

// Cards
$card-border-width: 0 !default;
$card-border-radius: 4px !default;
$card-bottom-margin: 30px !default;

// Dropdowns
$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: 0.65rem !default;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;
$dropdown-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
  0 15px 35px rgba(50, 50, 93, 0.15),
  0 5px 15px rgba(0, 0, 0, 0.1
);

// Buttons
$btn-secondary-border: $gray-300;
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
  0 3px 6px rgba(0, 0, 0, 0.08);
$btn-focus-box-shadow: $btn-hover-box-shadow  !default;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

// Tables
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
$table-hover-bg: $gray-100  !default;
$table-cell-padding: 1rem !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;

// Components
$component-active-color: $white  !default;
$component-active-bg: $themecolor  !default;

// Badges
$badge-font-size: 73% !default;
$badge-font-weight: $font-weight-light  !default;
$badge-padding-y: 0.35em !default;
$badge-padding-x: 0.7em !default;
$badge-pill-padding-x: 0.7em !default;
$badge-pill-padding-y: 1em !default;

// Forms
$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
$input-btn-focus-color: rgba(0, 0, 0, 0.25) !default;
$input-focus-border-color: rgba(0, 0, 0, 0.25) !default;
$input-btn-focus-box-shadow: none !default;
$input-alternative-box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15),
  0 1px 0 rgba(0, 0, 0, 0.02);
$input-focus-alternative-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);
$input-box-shadow: none !default;
$input-focus-box-shadow: none !default;
$custom-select-box-shadow: none !default;

// Border settings
$border-width: 1px !default;
$border-color: $gray-300  !default;
$border-radius: 2px !default;
$border-radius-lg: 4px !default;
$border-radius-sm: 1px !default;

// Progress bars
$progress-height: 6px !default;
$progress-bg: $gray-100;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xlg: 1600px,
  ) !default;

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xlg: 1600px,
  ) !default;

// Navbar
$navbar-dark-color: rgba($white, 0.8) !default;
$navbar-dark-hover-color: rgba($white, 1) !default;

// Modal
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.2) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.2) !default;

// Tabs
$nav-tabs-link-active-bg: $white  !default;
$nav-tabs-border-color: $gray-300  !default;
$nav-tabs-border-width: $border-width  !default;
$nav-tabs-border-radius: $border-radius  !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color  !default;
$nav-tabs-link-active-color: $gray-700  !default;
$nav-tabs-link-active-bg: $body-bg  !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg  !default;



// ...................Styles from EasyDev.........................//


$themes: (
  light: (colorBackground: white,
    colorBackgroundBody: #2a2a31,
    inboxButtonBackground: #f2f4f7,
    inboxButtonBackgroundHover: darken(#f2f4f7, 5%),
    colorHeaderDataPicker:#6db0ff,
    colorText: #646777,
    colorTextAdditional: #646777,
    // logoImg: url(../../shared/img/logo/logo_light.svg),
    colorHover: #fafbfe,
    colorFolderHover: #f0eeee,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    colorEmoji: #232329,
    sidebarColor: aliceblue,
  ),
  dark: (colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    inboxButtonBackground: #2a2a31,
    inboxButtonBackgroundHover: lighten(#2a2a31, 5%),
    colorHeaderDataPicker:#063263,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    // logoImg: url(../../shared/img/logo/logo_dark.svg),
    colorHover: #38373f,
    colorFolderHover: #ffffff1A,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorEmoji: #ffffff,
    sidebarColor: #232329,
    greenlynko: #1EF1C6)
);

@mixin themify($themes) {

  @each $theme,
  $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-accent: #4ce1b6;
$color-light-accent: #55e5bb;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-light-yellow: #f4dd87;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-dark-red: #721c24;
$color-light-red: #f88e7d;
$color-very-light-red: #f8d7da;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-light-blue: #87c3f7;
$color-blue-hover: darken($color-blue, 10%);

$color-black: #000000;
$color-gray: #787985;
$color-light-gray: #d8dfe9;
$color-dusty-white: #dddddd;
$color-white: #ffffff;

$color-green: #b8e986;
$color-greenlynko: #1EF1C6;
$color-green-hover: darken($color-green, 10%);

$color-dark-fields-border: #33333a;
$color-black-background: #232329;
$color-background-body: #f2f4f7;
$color-text: #646777;
$color-hover: #fafbfe;

$directions: (
  ltr: (direction: ltr,

    right: right,
    left: left,

    margin-right: 'margin-right',
    margin-left: 'margin-left',

    padding-left: 'padding-left',
    padding-right: 'padding-right',

    border-top-left-radius: 'border-top-left-radius',
    border-bottom-left-radius: 'border-bottom-left-radius',

    border-top-right-radius: 'border-top-right-radius',
    border-bottom-right-radius: 'border-bottom-right-radius',

    border-left: 'border-left',
    border-right: 'border-right',

    translate: 'translate(-50%, -50%);',
    mirrorY: 'scale(1, 1)',

    border-right-color: 'border-right-color',

    transform-sidebar: 'rotate(90deg)',

    flex-flow: row nowrap,
    row: 'row',

    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    '-': '-',
  ),
  rtl: (direction: rtl,

    right: left,
    left: right,

    margin-right: 'margin-left',
    margin-left: 'margin-right',

    padding-left: 'padding-right',
    padding-right: 'padding-left',

    border-top-left-radius: 'border-top-right-radius',
    border-bottom-left-radius: 'border-bottom-right-radius',

    border-top-right-radius: 'border-top-left-radius',
    border-bottom-right-radius: 'border-bottom-left-radius',

    border-left: 'border-right',
    border-right: 'border-left',

    translate: 'translate(50%, -50%);',
    mirrorY: 'scale(1, -1)',

    border-right-color: 'border-left-color',
    transform-sidebar: 'rotate(-90deg)',

    flex-flow: row-reverse nowrap,
    row: 'row-reverse',

    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(100%)),
    '-': '+',
  )
);

@mixin directify($directions) {

  @each $direction,
  $map in $directions {
    .#{$direction}-support & {
      $direction-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($directions, $direction), '#{$key}');
        $direction-map: map-merge($direction-map, ($key: $value)) !global;
      }

      @content;
      $direction-map: null !global;
    }
  }
}

@function directed($key) {
  @return map-get($direction-map, $key);
}

$borders: (
  round-borders-on: (borderRadius: 15px,
  ),
  round-borders-off: (borderRadius: 5px,
  )
);

@mixin borderify($borders) {

  @each $border,
  $map in $borders {
    .#{$border} & {
      $border-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($borders, $border), '#{$key}');
        $border-map: map-merge($border-map, ($key: $value)) !global;
      }

      @content;
      $border-map: null !global;
    }
  }
}

@function bordered($key) {
  @return map-get($border-map, $key);
}

$shadows: (
  blocks-shadows-on: (blocksShadows: 0 10px 30px 1px rgba(0, 0, 0, 0.06),
  ),
  blocks-shadows-off: (blocksShadows: none,
  )
);

@mixin shadowify($borders) {

  @each $shadow,
  $map in $shadows {
    .#{$shadow} & {
      $shadow-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($shadows, $shadow), '#{$key}');
        $shadow-map: map-merge($shadow-map, ($key: $value)) !global;
      }

      @content;
      $shadow-map: null !global;
    }
  }
}

@function shadowed($key) {
  @return map-get($shadow-map, $key);
}



.dashboard {

  //default

  .dashboard__visitors-chart-title {

    @include directify($directions) {
      text-align: directed('left');
    }

    span {
      color: $color-additional;
      font-size: 11px;
    }
  }

  .dashboard__visitors-chart-number {

    @include directify($directions) {
      text-align: directed('left');
    }

    font-size: 48px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .dashboard__audience-table {

    @include directify($directions) {
      text-align: directed('left');
    }

    .progress {
      margin-top: 10px;
    }

    .progress-bar {
      height: 10px;
    }
  }

  .dashboard__table-flag {
    width: 42px;
    height: 30px;

    @include directify($directions) {
      #{directed('margin-right')}: 25px;
    }
  }

  .dashboard__bounce-percent {
    @include directify($directions) {
      text-align: directed('left');
    }

    color: $color-accent;
    font-size: 48px;
    line-height: 48px;
    margin-top: 20px;
    margin-bottom: 45px;
  }

  //commerce

  .dashboard__product-img {
    max-height: 270px;
    height: 100%;
    padding: 10px 0 20px 0;
    margin-bottom: 15px;
    text-align: center;

    @include themify($themes) {
      border-bottom: 1px solid themed('colorBorder');
    }

    img {
      width: auto;
      max-width: 100%;
      max-height: 240px;
    }
  }

  .dashboard__product-title {
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .dashboard__product-subhead {
    @include directify($directions) {
      text-align: directed('left');
    }

    font-size: 13px;
    line-height: normal;
  }

  .dashboard__table-orders {
    overflow: hidden;
    min-width: 410px;

    tbody td {
      padding: 16px 10px;
    }

    .dashboard__table-orders-title {
      position: relative;

      @include directify($directions) {
        #{directed('padding-left')}: 52px;
      }

      display: flex;
      line-height: 15px;
      align-items: center;
    }
  }

  .dashboard__chart-pie--commerce,
  .dashboard__chart-pie--fitness {
    .recharts-legend-wrapper {
      @media screen and (min-width: 370px) {
        bottom: 0px !important;
      }

      @media screen and (min-width: 700px) {
        bottom: 70px !important;
      }

      @media screen and (min-width: 1020px) {
        bottom: -30px !important;
      }

      @media screen and (min-width: 1200px) {
        bottom: -55px !important;
      }

      @media screen and (min-width: 1400px) {
        bottom: -25px !important;
      }

      @media screen and (min-width: 1800px) {
        bottom: -30px !important;
      }
    }
  }

  .dashboard__table-orders-total {
    white-space: nowrap;
  }

  .dashboard__table-orders-img-wrap {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    @include directify($directions) {
      #{directed('left')}: 10px;
    }
  }

  .dashboard__table-orders-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .dashboard__table-orders-amount {
    display: flex;

    div {
      width: 3px;
      height: 14px;
      display: inline-block;
      background-color: $color-accent;
      border-radius: 3px;

      @include directify($directions) {
        #{directed('margin-right')}: 3px;
        #{directed('margin-left')}: 0px;
      }

      margin-top: auto;
      margin-bottom: auto;
    }

    span {
      color: $color-additional;

      @include directify($directions) {
        #{directed('margin-right')}: 0px;
        #{directed('margin-left')}: 5px;
      }

      margin-top: auto;
      margin-bottom: auto;
    }

    &.dashboard__table-orders-amount--medium {

      div {
        background-color: $color-yellow;
      }
    }

    &.dashboard__table-orders-amount--low {

      div {
        background-color: $color-red;
      }
    }
  }

  .dashboard__table-orders-link {
    font-size: 12px;
    margin-top: 20px;
    display: block;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .dashboard__table-more {
    display: flex;

    &>button {
      margin: 0;
      padding: 0 5px;
      border: none;
      background-color: transparent;

      @include directify($directions) {
        #{directed('margin-left')}: auto;
      }

      &:before {
        display: none;
      }

      svg {
        margin: 0;
        height: 20px;
        width: 20px;
        fill: $color-additional;
        transition: all 0.3s;
      }

      &:hover,
      &:not([disabled]):not(.disabled):active,
      &:focus {
        background: transparent;

        svg {
          fill: $color-accent;
        }
      }
    }

    &>div {
      min-width: 90px;
      width: 100%;
    }

    button {
      font-size: 13px;
    }
  }

  .dashboard__sales-toggle {

    label {
      margin: 0;

      &:after {
        box-shadow: none;
        // background-color: $color-red;
      }
    }
  }

  .dashboard__sales-toggle-left {
    // @include directify($directions) {
    //   #{directed('margin-left')}: auto;
    // }
    // @include directify($directions) {
    //   #{directed('margin-right')}: 12px;
    // }
    margin-right: 22px;
    color: $color-additional;
    pointer-events: none;
  }

  .dashboard__sales-toggle-right {
    margin-left: 22px;
    // @include directify($directions) {
    //   #{directed('margin-left')}: 12px;
    //   #{directed('margin-right')}: auto;
    // }
    color: $color-additional;
    pointer-events: none;
  }

  .dashboard__sales-report {
    display: flex;

    &>div {
      width: 100%;

      @include directify($directions) {
        #{directed('margin-right')}: 25px;
      }

      margin-bottom: 0;

      &:last-child {
        @include directify($directions) {
          #{directed('margin-right')}: 0px;
        }
      }
    }

    p {
      margin-top: 0;
    }
  }

  .dashboard__sales-report-title {
    color: $color-additional;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 10px;
  }

  .dashboard__sales-report-now {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .dashboard__sales-report-plan {
    font-size: 11px;
    line-height: 15px;
    color: $color-additional;
    opacity: 0.5;
    margin-bottom: 10px;
  }

  .dashboard__sales-report-value {
    margin-bottom: 0;
  }

  //fitness

  .dashboard__weight-stats {
    display: flex;
    margin-bottom: 20px;
  }

  .dashboard__weight-stat {
    @include directify($directions) {
      #{directed('margin-right')}: 40px;
    }

    @media screen and (min-width: 768px) {
      @include directify($directions) {
        #{directed('margin-right')}: 80px;
      }
    }

    &:last-child {
      @include directify($directions) {
        #{directed('margin-right')}: 0px;
      }
    }
  }

  .dashboard__weight-stat-title {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .dashboard__weight-stat-value {
    font-size: 36px;
    line-height: 48px;

    @media screen and (min-width: 768px) {
      font-size: 48px;
      line-height: 62px;
    }

    &.dashboard__weight-stat-value--control {
      color: $color-yellow;
    }

    &.dashboard__weight-stat-value--total {
      color: $color-red;
    }
  }

  .dashboard__health-chart-card {
    text-align: center;
  }

  .dashboard__goal {
    font-size: 12px;
    color: $color-additional;
  }

  .dashboard__health-chart {
    position: relative;
  }

  .dashboard__health-chart-info {
    position: absolute;
    width: 100%;
    top: calc(50% - 55px);
    animation: label 1.5s ease-in;

    p {
      margin: 0;
    }
  }

  .dashboard__health-chart-number {
    font-size: 56px;
    line-height: 60px;

    @include themify($themes) {
      color: themed('colorFitness');
    }
  }

  .dashboard__health-chart-units {
    color: $color-additional;
    margin-top: 5px;
  }

  .dashboard__competitor {
    display: flex;
    padding: 10px 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: calc(100% + 65px);
      top: 0;

      @include directify($directions) {
        #{directed('left')}: -30px;
      }

      z-index: 0;
      opacity: 0;
      transition: all 0.3s;

      @include themify($themes) {
        background: themed('colorHover');
      }
    }

    &:hover {

      &:before {
        opacity: 1;
      }
    }
  }

  .dashboard__competitor-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    @include directify($directions) {
      #{directed('margin-right')}: 10px;
    }

    min-width: 40px;
    z-index: 1;

    img {
      height: 100%;
      min-width: 100%;
    }
  }

  .dashboard__competitor-info {
    @include directify($directions) {
      text-align: directed('left');
    }

    z-index: 1;
  }

  .dashboard__competitor-name {
    font-weight: 500;
  }

  .dashboard__competitor-result {
    color: $color-red;
    margin-top: 0;
    font-size: 14px;
  }

  // > 2

  .dashboard__area {
    font-size: 11px;

    .recharts-legend-wrapper {
      bottom: 0 !important;
    }
  }

  .dashboard__total {
    display: flex;
    margin-top: -15px;

    &.dashboard__total--area {
      margin-top: -55px;
    }
  }

  .dashboard__chart-container {
    flex: 1;
    width: 0;
    min-width: 0;

    @media (min-width: 1200px) {
      & {
        min-width: 0;
        max-width: 180px;
      }
    }
  }

  .dashboard__total-stat {
    font-size: 18px;
    height: 24px;

    @include directify($directions) {
      #{directed('margin-right')}: auto;
      #{directed('padding-right')}: 10px;
    }

    margin-top: auto;
    line-height: 24px;
  }

  .dashboard__trend-icon {
    fill: $color-accent;
    height: 24px;
    width: 24px;

    @include directify($directions) {
      #{directed('margin-right')}: 3px;
      transform: #{directed('mirrorY')}
    }

    min-width: 24px;
    margin-top: auto;
  }

  .dashboard__stat {
    display: flex;
    margin-top: 40px;
    margin-bottom: 10px;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .dashboard__stat-info {
      padding-left: 60px;

      @include directify($directions) {
        #{directed('margin-right')}: 0px;
        #{directed('margin-left')}: 30px;

      }

      @include directify($directions) {
        text-align: directed('left');
      }

      margin-top: auto;
      margin-bottom: auto;

      p {
        color: $color-additional;
      }
    }

    .dashboard__stat-number {
      margin-top: 10px;
    }

    .dashboard__stat-chart {
      position: relative;
      text-align: center;
    }

    .dashboard__stat-label {
      position: absolute;
      font-size: 18px;
      line-height: 20px;
      top: calc(50% - 10px);
      width: 100%;
      margin: 0;
      animation: label 1.5s ease-in;
      color: $color-dusty-white;
    }

    &.dashboard__stat--budget {
      text-align: center;
      flex-wrap: wrap;

      .dashboard__stat-chart {
        margin: auto;
      }

      .dashboard__stat-label {
        font-size: 36px;
      }

      .dashboard__stat-main {
        width: 100%;

        hr {
          margin-bottom: 30px;
          margin-top: 40px;
        }
      }

      .dashboard__stat-main-title {
        color: $color-additional;
      }

      .dashboard__stat-main-number {
        color: $color-red;
        font-size: 48px;
        line-height: 34px;
        margin-top: 15px;
      }

      .dashboard__stat-data {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 10px;

        p {
          margin: 0;
        }
      }

      .dashboard__stat-data-number {
        font-size: 18px;
        line-height: 34px;
        font-weight: 500;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1439px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;

      .dashboard__stat-info {
        margin: 0;
      }

      .dashboard__stat-chart {
        margin-bottom: 15px;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1539px) {

      .dashboard__stat-chart {

        svg,
        div {
          width: 80px !important;
          height: 80px !important;
        }
      }
    }
  }

  @keyframes label {
    from {
      opacity: 0
    }

    to {
      opacity: 1
    }
  }

  .dashboard__chart-pie {
    @include directify($directions) {
      text-align: directed('left');
    }

    height: 280px !important;

    .recharts-surface {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: 768px) {

      &.dashboard__chart-pie--crypto {
        height: 300px !important;

        .dashboard__chart-pie-container {
          height: 300px !important;
        }
      }

      &.dashboard__chart-pie--commerce {
        height: 360px !important;

        .dashboard__chart-pie-container {
          height: 360px !important;
        }
      }

      &.dashboard__chart-pie--fitness {
        height: 360px !important;

        .dashboard__chart-pie-container {
          height: 360px !important;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .recharts-legend-wrapper {
        position: relative !important;
      }
    }

    @media screen and (max-width: 480px) {

      &.dashboard__chart-pie--crypto {
        height: 240px !important;

        .dashboard__chart-pie-container {
          height: 180px !important;
        }

        .dashboard__chart-legend {
          @include directify($directions) {
            #{directed('margin-left')}: -10px;
          }

          li {
            margin-top: 0;
          }
        }
      }
    }
  }

  .dashboard__chart-pie-container {
    @include directify($directions) {
      #{directed('padding-left')}: 0px;
    }

    height: 200px !important;
  }

  .dashboard__chart-legend {
    padding: 0;
    list-style: none;

    span {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;

      @include directify($directions) {
        #{directed('margin-right')}: 10px;
      }

      // didn't rtl
      margin-right: 10px;
    }

    li {
      margin-top: 5px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  .dashboard__card-widget {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  //crypto

  .dashboard__btc {
    color: #f6a81e;
  }

  .dashboard__eth {
    color: #5e62e6;
  }

  .dashboard__neo {
    color: #3ddb42;
  }

  .dashboard__ste {
    color: #21cbe6;
  }

  .dashboard__eos {
    color: #6d6a6a;
  }

  .dashboard__lit {
    color: #b7b3b3;
  }

  .dashboard__table-crypto {

    tbody td {
      padding: 17px 10px;
      white-space: nowrap;
    }

    .dashboard__table-crypto-chart {
      padding: 10px;
      width: 180px;
    }
  }

  .dashboard__place-order {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: 900px) {
      flex-wrap: nowrap;
    }

    @media screen and (min-width: 1200px) {
      flex-wrap: wrap;
    }

    @media screen and (min-width: 1360px) {
      flex-wrap: nowrap;
    }
  }

  // booking

  .dashboard__booking-card {
    padding-top: 20px;
    padding-bottom: 25px;
    padding-right: 30px;
    padding-left: 30px;

    .dashboard__booking-card-progress-label {
      @include directify($directions) {
        text-align: directed('right');
      }

      font-size: 14px;
      font-weight: 500;
    }

    .dashboard__trend-icon {
      fill: $color-light-gray;

      @include directify($directions) {
        #{directed('margin-right')}: 0px;
      }

      margin-top: auto;
    }
  }

  .dashboard__booking-total-container {
    display: flex;
    justify-content: space-between;
  }

  .dashboard__booking-total-title {
    direction: ltr;
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    color: $color-accent;
  }

  .dashboard__booking-total-title--red {
    color: $color-red;
  }

  .dashboard__booking-total-title--green {
    color: $color-green;
  }

  .dashboard__booking-total-title--blue {
    color: $color-blue;
  }

  .dashboard__booking-total-description {
    @include directify($directions) {
      text-align: directed('left');
    }

    opacity: 0.7;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: $color-gray;
    margin-top: 3px;
    text-transform: uppercase;

    .theme-dark & {
      color: $color-dusty-white;
    }
  }

  .dashboard__booking-reservations-panel:not(.panel--collapse) {
    height: calc(100% - 138px);
  }

  .dashboard__booking-reservations {

    @include directify($directions) {
      text-align: directed('left');
    }

    margin-top: -10px;

    &-title {
      font-size: 13px;
      color: $color-gray;

      .theme-dark & {
        color: $color-dusty-white;
      }
    }

    &-number {
      font-size: 48px;
      line-height: 34px;
      margin-top: 15px;
      margin-bottom: 10px;
      color: $color-gray;

      .theme-dark & {
        color: $color-dusty-white;
      }
    }

    &-chart {
      height: 180px;
      position: relative;

      @media screen and (min-width: 1400px) and (max-width: 1599px) {
        height: 230px;
      }

      .recharts-surface {
        width: 100%;
        height: 100%;
      }

      .recharts-legend-wrapper {
        width: 100% !important;
        display: block;
        position: static !important;

        @media screen and (min-width: 370px) {
          //width: 115px !important;
          bottom: 0px;
        }

        @media screen and (min-width: 992px) {
          //width: 105px !important;
          bottom: 70px;
        }

        @media screen and (min-width: 1020px) {
          //width: 115px !important;
          bottom: -30px;
        }

        @media screen and (min-width: 1400px) {
          //width: 125px !important;
          bottom: -55px;
        }

        @media screen and (min-width: 1800px) {
          //width: 115px !important;
          bottom: -30px;
        }
      }

      .recharts-responsive-container {
        width: 100% !important;
        height: 100% !important;

        @media screen and (min-width: 1400px) and (max-width: 1599px) {
          height: calc(100% - 18px) !important;
        }
      }

      &-container {
        width: 100% !important;
        height: 100% !important;

        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        @media screen and (min-width: 992px) {
          width: 100% !important;
          height: 100% !important;
        }

        @media screen and (min-width: 1400px) and (max-width: 1599px) {
          flex-direction: column;
          align-items: baseline;
        }
      }

      &-legend {
        li {
          display: flex;
          align-items: baseline;
        }

        p {
          display: inline-block;
          margin: 0;
        }
      }
    }

    &-link {
      color: $color-blue;
      font-size: 12px;
      line-height: 1.5;
      position: absolute;
      bottom: 0;
      cursor: pointer;

      @include directify($directions) {
        #{directed('left')}: 0px;
      }

      &-icon {
        width: 12px;
        height: 12px;
      }

      @media screen and (min-width: 1400px) {
        margin-top: 10px;
        display: inline-block;
        position: static;
      }
    }
  }

  .dashboard__booking-our-mission {
    @include directify($directions) {
      text-align: directed('left');
    }

    height: 108px;
    background: $color-green;
    border-radius: 5px;
    padding: 15px 13px;
    position: relative;
    overflow: hidden;

    svg {
      position: absolute;
      height: 80px;
      width: 80px;
      top: calc(50% - 40px);
      opacity: 0.3;

      @include directify($directions) {
        #{directed('right')}: -23px;
      }

      color: $color-white;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 1.25;
      color: $color-white;
      max-width: 100%;

      @media screen and (min-width: 376px) and (max-width: 991px) {
        max-width: calc(100% - 60px);
      }

      @media screen and (min-width: 1200px) and (max-width: 1550px) {
        font-size: 14px;
      }

      @media screen and (max-width: 420px) {
        font-size: 14px;
      }

    }

    .dashboard__booking-our-mission-title {
      font-size: 24px;
      font-weight: 500;
      color: $color-white;
      margin-bottom: 4px;

      @media screen and (min-width: 1200px) and (max-width: 1399px) {
        font-size: 20px;
      }
    }
  }


  .dashboard__weekly-stat {

    hr {
      margin-bottom: 20px;
    }

    &-chart {
      display: flex;
      margin: 0 0 31px 0;
      flex-wrap: wrap;
      justify-content: space-around;

      .recharts-surface {
        width: 100%;
        height: 100%;

      }

      &-item {
        width: 110px;
        margin-bottom: 15px;

        @media screen and (max-width: 370px) {
          @include directify($directions) {
            #{directed('margin-left')}: 10px;
          }
        }

        @media screen and (min-width: 1200px) {
          @include directify($directions) {
            #{directed('margin-left')}: 10px;
          }
        }

        @media screen and (min-width: 1400px) {
          @include directify($directions) {
            #{directed('margin-left')}: 0px;
          }
        }
      }

      &-pie {
        display: block;
        position: relative;
        text-align: center;
        height: 110px;

        .recharts-responsive-container {
          width: 100% !important;
        }
      }

      &-pie-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    &-label {
      position: absolute;
      top: calc(50% - 10px);
      width: 100%;
      margin: 0;
      animation: label 1.5s ease-in;
      font-size: 24px;
      line-height: 24px;
    }

    &-info {
      margin-top: 10px;
      font-size: 13px;
      line-height: 1.38;
      text-align: center;

      p {
        color: $color-additional;
      }
    }
  }

  .dashboard__social-stat {
    &-item {
      @include directify($directions) {
        text-align: directed('left');
      }

      display: flex;
      align-items: flex-end;
      width: 100%;

      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-title {
      width: 40%;
      font-size: 13px;
      line-height: 1.38;
      color: $color-additional;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-progress {
      width: 60%;
      margin-bottom: 5px;

      &.progress--wide {
        width: 80%;
      }

      .progress__label {
        @include directify($directions) {
          text-align: directed('right');
        }
      }
    }
  }

  .dashboard__occupancy-table {
    @include directify($directions) {
      text-align: directed('left');
    }

    td {
      font-size: 13px;
      padding: 5px;
    }

    td.td-head {
      color: $color-gray;

      .theme-dark & {
        color: $color-dusty-white;
      }
    }

    td.td-blue {
      font-weight: 500;
      color: $color-blue;
    }

    td.td-green {
      font-weight: 500;
      color: $color-green;
    }

    td.td-gray {
      color: $color-additional;
    }
  }

  .dashboard__place-order-form {
    position: relative;
    width: 100%;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    form {
      padding: 20px 20px 30px 20px;
      border-radius: 5px;
      margin-top: 10px;

      @include themify($themes) {
        background-color: themed('colorBorder');
      }
    }

    .form__form-group-label {
      width: 50px;
    }

    .form__form-group-field {
      width: calc(100% - 50px);
    }

    .form__button-toolbar {
      @include directify($directions) {
        #{directed('margin-left')}: 0px;
      }

      width: 100%;

      button {
        width: 100%;
      }
    }

    input {

      @include themify($themes) {
        background-color: themed('colorBackground');
      }
    }

    @media screen and (min-width: 900px) {
      width: calc(50% - 15px);
      margin-top: 0;
    }

    @media screen and (min-width: 1200px) {
      width: 100%;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (min-width: 1360px) {
      width: calc(50% - 15px);
      margin-top: 0;
    }
  }

  .dashboard__place-order-form-subhead {
    position: absolute;
    font-size: 12px;
    top: 4px;

    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  }

  .dashboard__chart-pie.dashboard__chart-pie--crypto {

    @media screen and (min-width: 1200px) {

      .dashboard__chart-pie-container {
        @include directify($directions) {
          #{directed('padding-left')}: 0px;
        }
      }

      .recharts-legend-wrapper {
        bottom: -110px !important;
      }
    }

    @media screen and (min-width: 1360px) {

      .recharts-legend-wrapper {
        bottom: 5px !important;
      }
    }
  }

  //app

  .mobile-app-widget {

    .mobile-app-widget__top-line {
      display: flex;

      .mobile-app-widget__total-stat {
        direction: ltr;
        padding: 0;
        font-size: 28px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;

        @include directify($directions) {
          #{directed('margin-right')}: auto;
        }

        line-height: normal;
      }
    }

    .mobile-app-widget__top-line--pink {
      .mobile-app-widget__total-stat {
        color: #ff4861;
      }
    }

    .mobile-app-widget__top-line--lime {
      .mobile-app-widget__total-stat {
        color: #b8e986;
      }
    }

    .mobile-app-widget__top-line--blue {
      .mobile-app-widget__total-stat {
        color: #48b5ff;
      }
    }

    .mobile-app-widget__top-line--turquoise {
      .mobile-app-widget__total-stat {
        color: #4ce1b6;
      }
    }

    .mobile-app-widget__title {
      @include directify($directions) {
        text-align: directed('left');
      }

      text-transform: uppercase;
      position: relative;
      margin: 0 0 20px;

      h5 {
        font-size: 12px;
        color: $color-gray;
        font-weight: 500;
      }
    }

    .dashboard__trend-icon {
      margin: 0;
      height: 29px;
      min-width: 28px;
      fill: $color-light-gray;
    }

    .progress__label {
      font-size: 14px;
      font-weight: 500;
    }

    .progress__label {
      @include directify($directions) {
        #{directed('right')}: -7px;
      }
    }

    .progress-bar {
      border-radius: 5px;
      background-color: unset;
    }

  }

  .dashboard__users-stat {
    .dashboard__trend-icon {
      fill: $color-light-gray;
      height: 24px;
      width: 24px;

      @include directify($directions) {
        #{directed('margin-right')}: 3px;
      }

      min-width: 24px;
      margin-top: auto;
    }
  }

  .dashboard__current-users {
    display: flex;
    align-items: center;
    flex-direction: column;

    .dashboard__current-users-chart {

      position: relative;
      width: 100%;

      div,
      svg {
        width: 100% !important;
        margin: 0 auto;
      }

      .dashboard__current-users-label {
        margin: 0;
        position: absolute;
        top: calc(50% + 10px);
        text-align: center;

        @include directify($directions) {
          #{directed('left')}: calc(50% - 30px);
        }

        font-size: 38px;
      }
    }

    .dashboard__current-users-info {
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      font-size: 13px;
      line-height: 1.54;
      color: $color-gray;

      p {
        margin: 0;
      }

      .dashboard__current-users-day {

        display: flex;
        flex-direction: column;
        align-items: center;

        & span:last-child {
          font-size: 16px;
          line-height: 1.25;
          font-weight: 500;
          color: $color-gray;
        }
      }
    }

    @media screen and (min-width: 1539px) {
      .dashboard__current-users-info {
        padding: 0 20px;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1539px) {

      .dashboard__current-users-chart {

        svg,
        div {
          height: 100px !important;
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {

      .dashboard__current-users-info {
        padding: 0 75px;
      }
    }

  }

  .dashboard__active-users-chart {
    overflow: hidden;
  }

  .dashboard__carousel {
    margin-bottom: -25px;

    .slick-dots {
      bottom: -35px;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      background: none;
      opacity: 0;
      pointer-events: none;
    }

    .dashboard__carousel-slide {
      @include directify($directions) {
        text-align: directed('left');
      }

      border-radius: 5px;
      padding: 15px 13px;
      position: relative;
      overflow: hidden;

      svg {
        position: absolute;
        height: 80px;
        width: 80px;
        top: calc(50% - 40px);
        opacity: 0.3;

        @include directify($directions) {
          #{directed('right')}: -20px;
        }

        @include themify($themes) {
          fill: themed('colorIcon');
        }
      }

      p {
        color: $color-additional;
        margin: 0;
      }

      .dashboard__carousel-title {
        color: $color-red;
        font-size: 24px;
        font-weight: 500;
      }

      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &.dashboard__carousel-slide--red {
        background: $color-red;

        p {
          color: white;
          opacity: 0.45;
        }

        svg {
          fill: $color-white;
        }

        .dashboard__carousel-title {
          opacity: 1;
        }
      }
    }
  }
}



.panel {
  transition: 0.3s;

  &.panel--collapse {
    height: 108px;

    &.panel--divider .panel__title,
    .panel__title {
      margin-bottom: 0;

      .subhead {
        opacity: 0;
      }
    }

    &.panel--success,
    &.panel--primary,
    &.panel--warning,
    &.panel--danger {
      height: 72px;
    }

    .panel__body {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  &.panel--narrow {

    .panel__title {
      margin-bottom: 0;
    }

  }

  &.panel--divider {

    .panel__title {
      margin-bottom: 15px;
    }

    .panel__content {
      @include directify($directions) {
        text-align: directed('left');
      }

      padding-top: 0px;
      padding-bottom: 40px;

      .tabs--bordered-bottom {
        .nav-item {
          border-bottom: 1px solid #dee2e6;

          .nav-link {
            @include themify($themes) {
              background-color: themed('colorBackground');
              color: themed('colorText');
            }
          }
        }

      }
    }
  }

  &.panel--success,
  &.panel--primary,
  &.panel--warning,
  &.panel--danger {

    .panel__body {
      padding: 0;
    }

    .panel__title {
      @include directify($directions) {
        #{directed('padding-right')}: 40px;
        #{directed('padding-left')}: 30px;
      }

      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;

      h5 {
        color: white;
      }
    }

    .panel__btns {
      top: 13px;

      svg {
        fill: $color-white;
      }
    }

    .panel__content {
      @include directify($directions) {
        #{directed('padding-right')}: 35px;
        #{directed('padding-left')}: 30px;
      }

      padding-top: 0px;
      padding-bottom: 40px;
    }
  }

  &.panel--success {

    .panel__title {
      background-color: $color-accent;
    }
  }

  &.panel--danger {

    .panel__title {
      background-color: $color-red;
    }
  }

  &.panel--warning {

    .panel__title {
      background-color: $color-yellow;
    }
  }

  &.panel--primary {

    .panel__title {
      background-color: $color-blue;
    }
  }
}

.panel__refresh {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  @include directify($directions) {
    #{directed('left')}: 0px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;

    @include directify($directions) {
      #{directed('left')}: 0px;
    }

    border-radius: 5px;

    @include themify($themes) {
      background-color: themed('colorBackground');
      opacity: 0.8;
    }
  }

  svg {
    fill: $color-accent;
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);

    @include directify($directions) {
      #{directed('left')}: calc(50% - 24px);
    }
  }

  @keyframes refresh {

    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.panel__body {
  position: relative;

  @include directify($directions) {
    #{directed('padding-right')}: 35px;
    #{directed('padding-left')}: 30px;
  }

  padding-top: 30px;
  padding-bottom: 50px;
  transition: height 0.3s;
}

.panel__btns {
  position: absolute;
  top: 30px;

  @include directify($directions) {
    #{directed('left')}: 50px;
  }

  right: 10px;
}

.panel__btn {
  width: 14px;
  height: 14px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }

  svg {
    width: 14px;
    height: 14px;
    fill: $color-additional;
  }

  &:focus {
    outline: none;
  }
}

.panel__title {
  text-transform: uppercase;
  margin-bottom: 30px;
  transition: 0.3s;

  h5 {
    font-size: 13px;
  }

  .subhead {
    @include directify($directions) {
      text-align: directed('left');
    }

    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    transition: 0.3s;
  }
}

.panel__label {
  background-color: $color-accent;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 13px;
  padding: 3px 10px;

  @include directify($directions) {
    #{directed('left')}: 100%;
    #{directed('margin-left')}: 10px;
  }
}

.panel__icon {
  @include directify($directions) {
    display: inline-block;
    #{directed('margin-right')}: 5px;
  }
}


//.......................charts style................77

.recharts-text {
  background-color: black;

  @include themify($themes) {
    fill: themed('colorText');
  }

  opacity: 1;
}

.recharts-tooltip-wrapper {

  .recharts-default-tooltip {
    background-color: black;

    @include themify($themes) {
      background: themed('colorBackgroundBody') !important;
    }

    border: 0 !important;
  }

}

//................................EasyDev Tooltip Style...............................//

.tooltip {
  z-index: 99;

  .tooltip-inner {
    background-color: $color-gray;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 15px;
  }

  &.bs-tooltip-right .arrow::before,
  &.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: $color-gray;
  }

  &.bs-tooltip-top .arrow::before,
  &.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: $color-gray;
  }

  &.bs-tooltip-bottom .arrow::before,
  &.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: $color-gray;
  }

  &.bs-tooltip-left .arrow::before,
  &.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: $color-gray;
  }
}

.card-tooltip {
  @media (min-width: 1426px) {
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1200px) and (max-width: 1425px) {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.button-tooltip {
  margin-left: auto !important;
  margin-right: auto !important;
}


//.....................EasyDev Material Table...............Material table...................//@at-root

.material-table {

  th {
    white-space: nowrap;
  }
}

.material-table__toolbar-wrap {
  position: absolute;
  top: 10px;

  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  .material-table__toolbar {
    padding: 0 30px;
  }

  .material-table__toolbar-button {
    height: 36px;
    width: 36px;
    padding: 0;
  }
}

.material-table__toolbar-selected {
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }
}

.material-table__row {
  transition: 0.3s;
  cursor: pointer;


  &[aria-checked="true"] {

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    .material-table__checkbox span {
      color: $color-accent;
    }
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }
}

.material-table__checkbox--checked span {
  color: $color-accent;
}

.material-table__cell-right {
  @include directify($directions) {
    text-align: directed('left');
  }

}

.material-table__cell.material-table__cell--sort {

  span {
    transition: 0.3s;

    @include themify($themes) {
      color: themed('colorText');
    }

    &:hover {
      color: $color-accent;
    }
  }
}

.material-table__cell,
.material-table__sort-label,
.material-table__toolbar-button {
  @include themify($themes) {
    color: themed('colorText');
  }
}

.material-table__checkbox {
  transition: 0.3s;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.material-table__pagination {
  @include directify($directions) {
    float: directed('right');
  }

  button:hover {
    @include themify($themes) {
      background-color: themed('colorHover') !important;
    }
  }

  &>div {
    padding: 0;
  }

  span,
  div,
  svg {

    @include themify($themes) {
      color: themed('colorText');
    }
  }
}

.material-table__wrap {
  overflow-x: auto;
}

.material-table__filter-menu {

  div:last-child {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }

  .material-table__filter-menu-item {
    transition: 0.3s;
    font-size: 13px;
    padding: 7px 24px;
    height: auto;

    @include themify($themes) {
      color: themed('colorText');
      background: themed('colorBackground');
    }

    &:hover {
      @include themify($themes) {
        background: themed('colorHover');
      }
    }
  }
}


@media screen and (max-width: 768px) {

  .material-table__pagination {

    div {
      margin-left: 8px;
      margin-right: 8px;

      &:last-child {
        @include directify($directions) {
          #{directed('margin-right')}: 0px;
        }
      }

      &:first-child {
        margin: 0;
      }
    }

    div>span:first-of-type {
      display: none;
    }
  }
}

@media screen and (max-width: 420px) {

  .material-table__toolbar-selected {

    span {
      display: none;
    }
  }
}