//Buttons
.btn {
  position: relative;
  transition: $transition-base;

  &:hover {
    @include box-shadow($btn-hover-box-shadow);
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -o-transform: translateY(-1px);
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }
}
.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
  &.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
  }
  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px;
  }
  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
  }
}

.btn-rounded {
  border-radius: 60px;
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
  &.btn-lg {
    padding: 0.75rem 1.5rem;
  }
  &.btn-sm {
    padding: 0.25rem 0.75rem;
    font-size: 12px;
  }
  &.btn-md {
    padding: 12px 35px;
    font-size: 16px;
  }
}
// Brand buttons

@each $color, $value in $brand-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

.button-group .btn {
  margin-bottom: 5px;
  margin-right: 5px;
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px;
}

.btn .text-active {
  display: none;
}

.btn.active .text-active {
  display: inline-block;
}

.btn.active .text {
  display: none;
}


// .toggle-btn {
//   justify-content: flex-start;
//   display: inline-flex;
//   width: 100%;
//   padding: 4px 0;
//   margin-bottom: 0;
//   cursor: pointer;

//   .toggle-btn__input {
//     height: 0;
//     width: 0;
//     visibility: hidden;
//   }
// }

// .toggle-btn__input-label {
//   cursor: pointer;
//   text-indent: -9999px;
//   width: 40px;
//   height: 20px;
//   display: block;
//   border-radius: 23px;
//   position: relative;
//   margin-bottom: 0;
//   @include directify($directions) {
//     #{directed('margin-left')}: auto;
//   }
//   border: none;

//   @include themify($themes) {
//     background-color: themed('colorBorder');
//   }

//   &::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     @include directify($directions) {
//       #{directed('left')}: 0px;
//     }
//     width: 20px;
//     height: 20px;
//     border-radius: 50%;
//     transition: 0.3s;
//     box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
//   }
// }

// .toggle-btn__input:checked ~ .toggle-btn__input-label {

//   &:after {
//     background: $color-accent;
//     // doesn't do RTL, it break toggles
//     left: 100%;
//     transform: translateX(-100%);
//   }
// }



