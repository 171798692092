.pac-container {
	background-color: #151515;
	margin-left: -10px;
	border: none;
	width: 238px !important;
}

.pac-item,
.pac-item-query {
	color: #f7f7f7;
	border: none;
	min-width: 500px;
}

.pac-item:hover {
	background: #6666;
}

.gm-style .gm-style-iw {
  border-radius: 10px;
  background-color: #E6E6E6 !important;
}  

.gm-style-iw-d {
	overflow: hidden !important;
	padding-right: 10px;
	padding-bottom: 10px;
	background-color: #E6E6E6 !important;
}

@media screen and (min-width: 700px) and (max-width: 1600px) {
    .gm-style-iw-d {
		overflow: auto !important;
	}
}

.gm-style .gm-style-iw-tc::after {
	background-color: transparent;
}


::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #E6E6E6 !important;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000;
}

/*     Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #E6E6E6;
}