/* import LynkcoTypeBold from 'src/fonts/LynkcoType-Bold.ttf';
import LynkcoTypeLight from 'src/fonts/LynkcoType-Light.ttf';
import LynkcoTypeMedium from 'src/fonts/LynkcoType-Medium.ttf';
import LynkcoTypeRegular from 'src/fonts/LynkcoType-Regular.ttf'; */

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    opacity: 0.3;
    background-color: #101010;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000;
}

/*     Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #373737;
}

@font-face {
    font-family: "LynkcoTypeLight";
    src: url("src/fonts/LynkcoType-Light.ttf") format("truetype");
}

@font-face {
    font-family: "LynkcoTypeRegular";
    src: url("src/fonts/LynkcoType-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "LynkcoTypeMedium";
    src: url("src/fonts/LynkcoType-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "LynkcoTypeBold";
    src: url("src/fonts/LynkcoType-Bold.ttf") format("truetype");
}


/* 
body {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 50%, rgba(34, 34, 34, 1) 99%);
    font-family: "MyFont";
} */

#containerbody {
    padding-left: 40px;
    padding-right: 0px;
}

#TabletitlesPoolCars {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
}

#Tabletitles {
    display: inline;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.cardcolor {
    background-color: #000000;
}

.horizontal-line {
    border-bottom: 3px solid #808080;
}

/*images styling*/

.photo1 {
    height: 57px;
    width: 58px;
    margin-right: 5px;
}

.photo2 {
    height: 50px;
    width: 60px;
}

.photo3 {
    height: 50px;
    width: 8px;
    margin-right: 5px;
}

.photo4 {
    height: 49px;
    width: 40px;
    margin-right: -11px;
}

.photo {
    height: 30px;
    width: 42px;
}

.image-container:hover .tableviewSquare {
    filter: brightness(50%);
}

.tableviewSquare {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    float: right;
    cursor: pointer;
    transition: filter 0.3s ease;
}

.tableviewlist {
    height: 20px;
    width: 20px;
    float: right;
    cursor: pointer;
}

/*table*/

/* #tablecard {
    background-color: #000000;
    width: 100%
} */



.tableFixHead {
    height: 550px;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
}

.tableFixHead thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}

#customers {
    /* font-family: "MyFont"; */
    border-spacing: 0;
    width: 100%;
    color: white;
    border-collapse: collapse;
    /* make the table borders collapse to each other */
    width: 100%;
}

#customers th {
    background: #000000;
    position: sticky;
    z-index: 100;
}

#customers th:nth-child(2) {
    /* padding-right: 20px; */
}


#customers td {
    /* border-bottom: 5px solid #000000; */
    padding-left: 20px;
    white-space: nowrap;
}

#customers td:nth-child(1) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    padding-right: 500px;

    /* border-bottom: 5px solid #000000; */
}

#customers td:nth-child(2) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    padding-right: 500px;

    /* border-bottom: 5px solid #000000; */
}

#customers tr {
    /* background-image: linear-gradient(97deg, rgba(10, 10, 10, 1) 0%, rgba(37, 37, 37, 1) 99%); */
    align-items: center;
    padding-left: 20px;
}



#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: white;
    padding-left: 20px;
}


#admin {
    /* font-family: "MyFont"; */
    border-spacing: 0;
    width: 100%;
    color: white;
    border-collapse: collapse;
    /* make the table borders collapse to each other */
    width: 100%;
}

#admin th {
    background: #000000;
    font-size: 14px;
    position: sticky;
    z-index: 100;
}

#admin th:nth-child(2) {
    padding-right: 20px;
}


#admin td {
    /* border-bottom: 5px solid #000000; */
    padding-left: 20px;
    white-space: nowrap;
}

#admin td:nth-child(3) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    padding-right: 150px;

    /* border-bottom: 5px solid #000000; */
}

#admin tr {
    /* background-image: linear-gradient(97deg, rgba(10, 10, 10, 1) 0%, rgba(37, 37, 37, 1) 99%); */
    align-items: center;
    padding-left: 20px;
}



#admin th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: white;
    padding-left: 20px;
}


.fixed_header {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /* border: 1px solid #ddd; */
}



.fixed_header th,
.fixed_header td {
    text-align: left;
    padding: 8px;
}


/*cardbody-cardallowance*/

#CardBodyCardAllowance {
    background-image: linear-gradient(135deg, #0A0A0A, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    height: 198px;
}

#CardBodyCardAllowance2 {
    background-image: linear-gradient(135deg, #000000, #272727 99%);
    border-radius: 15px;
    /* font-family: "MyFont"; */
    /* box-shadow: 1px 5px 1px #000000; */
    box-shadow: 3px 5px 6px #000000;
    height: 720px;
    margin-top: -5px;
}

#archEuropeDetailscard {
    /* background-image: linear-gradient(135deg, #000000, #272727 99%); */
    background-color: transparent;
    border-radius: 15px;
    /* font-family: "MyFont"; */
    /* box-shadow: 1px 5px 1px #000000; */
    /* box-shadow: 3px 5px 6px #000000; */
    height: 700px;
    margin-top: -55px;
    /* background-color: transparent; */
    margin-left: -127px;
    margin-right: -200px;
}

@media screen and (min-width: 1446px) and (max-width: 1600px) {
    #archEuropeDetailscard {
        height: 610px;
    }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
    #archEuropeDetailscard {
        margin-top: -70px;
    }
}

@media screen and (min-width: 700px) and (max-width: 800px) {
    #archEuropeDetailscard {
        margin-top: -95px;
    }
}

#CardBodyCardAllowanceVSP {
    background-image: linear-gradient(135deg, #101010 20%, #1B1B1B);
    border-radius: 4px;
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    height: 495px;
    margin-top: -5px;
}

#CardBodyCardAllowance3 {
    /* background-image: linear-gradient(135deg, #000000, #272727 99%); */
    background-color: transparent;

    /* border-bottom-left-radius: 10px; */
    /* font-family: "MyFont"; */
    /* box-shadow: 3px 5px 6px #000000; */
    position: block;
    display: flex;
    border: 0;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardAllowance7 {
    /* background-image: linear-gradient(135deg, #101010 10%, #1B1B1B 60%); */
    background-image: linear-gradient(135deg, #101010, #1B1B1B 50%);
    /* border-bottom-left-radius: 10px; */
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardAllowance8 {
    background-image: linear-gradient(135deg, #101010, #1B1B1B 50%);
    border-radius: 4px;
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    height: 720px;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardAllowance1 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    /* height: 130px; */
    height: 14vh;
}

#CardBodyCardAllowance4 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    /* height: 450px; */
    height: 51.2vh;

}

#CardBodyCardAllowance5 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    height: 200px;
}

#CardBodyCardAllowance6 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
}

.tableresponsive {
    width: 100%;
    overflow-y: auto;
}

#CardBody4layers {
    background-image: linear-gradient(135deg, #000000 20%, #1b1b1b 99%);
    border-radius: 4px;
    font-family: "MyFont";
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    padding: 10px;
}


#CardBody4layers td {
    color: white;
    white-space: nowrap;
    /*word-break: break-all;*/
}




#CardBody4layers tr {
    align-items: center;
    color: white;
}

#CardBody4layers td:first-child {
    font-size: 55px;
    font-family: "MyFontMedium";
    color: #A9A9A9;
    padding-left: 5px;
}

#CardBody4layers td:nth-child(2) {
    font-size: 18px;
    padding-left: 20px;
    right: 20px;
    color: #A9A9A9;
}

#CardBody4layers td:last-child {
    /*float: right;*/
    /*  padding-top: 13px;*/
    text-align: right
}

/* #CardBody4layers tr:first-child {
        display: none;
    }*/
/*car allowance button*/
#carallowancebtn {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: transparent;
    width: 100%;
    border-color: #DC1860;
    color: #DC1860;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: -5px 2px;
    cursor: pointer;
    height: 50px;
    /* margin: 0; */
}

#employeecarbtn {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: transparent;
    width: 100%;
    border-color: Turquoise;
    color: Turquoise;
}

#ArrowRightIconstyle {
    float: right;
}

.table th,
.table td {
    padding: 1rem;
    vertical-align: middle;
}

.tableFixHead {
    height: 93vh;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
    margin-top: 30px;
}

@media screen and (max-width: 600px) {
    .tableFixHead {
        height: 90vh;
        margin-left: -100px;
        margin-top: -10px;
    }
}

.tableFixHead thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}



.tableFixHeadCarPool {
    height: 680px;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
    margin-top: -110px;
    margin-left: -97px;
    margin-right: -33px;
}

/* @media only screen and (min-width: 600px) and (max-width: 1110px) {
    .tableFixHeadCarPool {
        overflow-x: hidden;
        margin-left: -100px;
        margin-right: 30px;
    }
} */

@media only screen and (min-width: 600px) and (max-width: 850px) {
    .tableFixHeadCarPool {
        overflow-x: hidden;
        margin-left: -100px;
        margin-right: 30px;
        margin-top: -140px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .tableFixHeadCarPool {
        overflow-x: hidden;
        margin-left: -100px;
        margin-right: 30px;
        margin-top: -150px;
    }
}

@media only screen and (min-width: 851px) and (max-width: 1025px) {
    .tableFixHeadCarPool {
        overflow-x: hidden;
        margin-left: -100px;
        margin-right: 30px;
        height: 500px;
        margin-top: -150px;
    }
}

@media screen and (min-width: 1276px) and (max-width: 1400px) {
    .tableFixHeadCarPool {
        margin-top: -130px;
        margin-left: -97px;
        margin-right: -40px;
        height: 350px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1445px) {
    .tableFixHeadCarPool {
        margin-top: -110px;
        margin-left: -105px;
        margin-right: -82px;
        height: 500px;
    }
}

@media screen and (min-width: 1446px) and (max-width: 1600px) {
    .tableFixHeadCarPool {
        margin-top: -110px;
        margin-left: -105px;
        margin-right: -82px;
        height: 550px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .tableFixHeadCarPool {
        overflow-x: auto;
        margin-left: -100px;
        margin-right: 60px;
        margin-top: -165px;
    }
}


@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .tableFixHeadCarPool {
        /* overflow-x: auto; */
        overflow-y: hidden;
        height: 400px;
        margin-top: -130px;
        height: 620px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .tableFixHeadCarPool {
        height: 680px;
        /* gives an initial height of 200px to the table */
        overflow: auto;
        /* make the table scrollable if height is more than 200 px  */
        overflow-x: hidden;
        margin-top: -110px;
        margin-left: -178px;
        margin-right: -430px;
    }
}


.tableFixHeadCarPool thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}

.tableFixHeadUnassigned {
    height: 450px;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: -100px;
    margin-left: -32px;
    margin-right: 160px;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .tableFixHeadUnassigned {
        overflow-x: auto;
        margin-left: -100px;
        margin-right: 60px;
        margin-top: -165px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 850px) {
    .tableFixHeadUnassigned {
        overflow-x: hidden;
        margin-left: -90px;
        margin-right: 160px;
        margin-top: -10px;
        height: 650px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .tableFixHeadUnassigned {
        overflow-x: hidden;
        margin-left: -96px;
        margin-right: 170px;
        margin-top: 5px;
        height: 600px;
    }
}

@media only screen and (min-width: 851px) and (max-width: 1025px) {
    .tableFixHeadUnassigned {
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -88px;
        margin-right: 170px;
        margin-top: 15px;
        height: 500px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .tableFixHeadUnassigned {
        /* overflow-x: auto; */
        /* overflow-y: hidden; */
        height: 350px;
        margin-top: -5px;

    }
}

@media screen and (min-width: 1276px) and (max-width: 1400px) {
    .tableFixHeadUnassigned {
        height: 400px;
        margin-top: 230px;
        margin-left: -38px;
        margin-right: 140px;
    }
}


@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .tableFixHeadUnassigned {
        height: 500px;
        margin-top: -80px;
        margin-left: -23px;
        margin-right: 130px;
        overflow-y: hidden;
        /* overflow-x: hidden; */
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .tableFixHeadUnassigned {
        height: 600px;
        margin-top: -80px;
        margin-left: -15px;
        margin-right: 135px;
        overflow-y: hidden;
        /* overflow-x: hidden; */
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .tableFixHeadUnassigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        overflow: auto;
        /* make the table scrollable if height is more than 200 px  */
        overflow-x: hidden;
        margin-top: 10px;
        margin-left: 13px;
        margin-right: 5px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .tableFixHeadUnassigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        overflow: auto;
        /* make the table scrollable if height is more than 200 px  */
        overflow-x: hidden;
        margin-top: -130px;
        margin-left: 8px;
        margin-right: 10px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .tableFixHeadUnassigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        overflow: auto;
        /* make the table scrollable if height is more than 200 px  */
        overflow-x: hidden;
        margin-top: -130px;
        margin-left: 30px;
        margin-right: -1000px;
    }
}

.tableFixHeadUnassigned thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}

.tableFixHeadAssigned {
    height: 450px;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: -90px;
    margin-left: -40px;
    margin-right: 160px;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .tableFixHeadAssigned {
        overflow-x: auto;
        margin-left: -100px;
        margin-right: 60px;
        margin-top: -165px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 850px) {
    .tableFixHeadAssigned {
        overflow-x: hidden;
        margin-left: -80px;
        margin-right: 170px;
        margin-top: -10px;
        height: 650px;
    }
}

@media only screen and (min-width: 851px) and (max-width: 1025px) {
    .tableFixHeadAssigned {
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -80px;
        margin-right: 170px;
        margin-top: 5px;
        height: 500px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .tableFixHeadAssigned {
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -80px;
        margin-right: 170px;
        margin-top: 5px;
        height: 600px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .tableFixHeadAssigned {
        /* overflow-x: auto; */
        /* overflow-y: hidden; */
        margin-left: -40px;
        height: 350px;
        margin-top: -5px;

    }
}

@media screen and (min-width: 1276px) and (max-width: 1400px) {
    .tableFixHeadAssigned {
        height: 400px;
        margin-top: 10px;
        margin-left: -38px;
        margin-right: 140px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .tableFixHeadAssigned {
        height: 550px;
        margin-top: -80px;
        margin-left: -35px;
        margin-right: 135px;
        overflow-y: hidden;
        /* overflow-x: hidden; */
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .tableFixHeadAssigned {
        height: 500px;
        margin-top: -80px;
        margin-left: -38px;
        margin-right: 130px;
        overflow-y: hidden;
        /* overflow-x: hidden; */
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .tableFixHeadAssigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        overflow: auto;
        /* make the table scrollable if height is more than 200 px  */
        overflow-x: hidden;
        margin-top: 10px;
        margin-left: -15px;
        margin-right: 10px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .tableFixHeadAssigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        /* overflow: auto; */
        /* make the table scrollable if height is more than 200 px  */
        /* overflow-x: hidden; */
        margin-top: -130px;
        margin-left: 5px;
        margin-right: 10px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .tableFixHeadAssigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        /* overflow: auto; */
        /* make the table scrollable if height is more than 200 px  */
        /* overflow-x: hidden; */
        margin-top: -120px;
        margin-left: 20px;
        margin-right: -30px;
    }
}

.tableFixHeadAssigned thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}

.fontStylingDriver {
    float: left;
    font-size: 30rem;
    margin-top: 25px;
    background: -webkit-linear-gradient(270deg, rgba(34, 34, 34, 1) 40%, rgba(5, 5, 5, 0.5) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 100px) {
    .fontStylingDriver {
        font-size: 2rem;
        margin-bottom: 180px;
        margin-top: 105px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-left: -140px;
    }
}

.fontStylingDriver {
    font-size: 8rem;
    margin-top: 65px;
    margin-bottom: 80px;
    padding-left: 17px;
    text-wrap: nowrap;
    white-space: nowrap;
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .fontStylingDriver {
        font-size: 5.5rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 10px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .fontStylingDriver {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 17px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

/* @media only screen and (min-width: 600px) and (max-width: 1000px) {
    .fontStylingDriver {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -16.7vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
} */

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .fontStylingDriver {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -18.2vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
   .fontStylingDriver {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -16.7vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .fontStylingDriver {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -14vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .fontStylingDriver {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -12.9vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .fontStylingDriver {
        font-size: 8rem;
        padding-left: 17px;
        margin-top: 70px;
        margin-bottom: 70px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .fontStylingDriver {
        font-size: 14.5rem;
        margin-left: -6vw;
        margin-top: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 70px;
    }
}


.fontStylingUnassigned {
    float: left;
    font-size: 30rem;
    margin-top: 25px;
    background: -webkit-linear-gradient(270deg, rgba(34, 34, 34, 1) 40%, rgba(5, 5, 5, 0.5) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 100px) {
    .fontStylingUnassigned {
        font-size: 2rem;
        margin-bottom: 180px;
        margin-top: 105px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-left: -140px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .fontStylingUnassigned {
        font-size: 8rem;
        margin-top: 65px;
        padding-left: 33px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .fontStylingUnassigned {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 22px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
    .fontStylingUnassigned {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 17px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1300px) {
    .fontStylingUnassigned {
        font-size: 5rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 28px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .fontStylingUnassigned {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -18.8vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .fontStylingUnassigned {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -18vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .fontStylingUnassigned {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -15.4vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .fontStylingUnassigned {
        font-size: 8rem;
        padding-left: 26px;
        margin-top: 70px;
        margin-bottom: 70px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .fontStylingUnassigned {
        font-size: 11.5rem;
        margin-left: -5.65vw;
        margin-top: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 70px;
    }
}

.fontStylingAssigned {
    float: left;
    font-size: 30rem;
    margin-top: 25px;
    background: -webkit-linear-gradient(270deg, rgba(34, 34, 34, 1) 40%, rgba(5, 5, 5, 0.5) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 100px) {
    .fontStylingAssigned {
        font-size: 2rem;
        margin-bottom: 180px;
        margin-top: 105px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-left: -140px;
    }
}

.fontStylingAssigned {
    font-size: 8rem;
    margin-top: 65px;
    margin-bottom: 80px;
    padding-left: 17px;
    text-wrap: nowrap;
    white-space: nowrap;
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .fontStylingAssigned {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 10px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .fontStylingAssigned {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 17px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .fontStylingAssigned {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -19.2vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .fontStylingAssigned {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -18.2vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .fontStylingAssigned {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -15.3vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .fontStylingAssigned {
        font-size: 8rem;
        padding-left: 22px;
        margin-top: 70px;
        margin-bottom: 70px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .fontStylingAssigned {
        font-size: 14.5rem;
        margin-left: -6vw;
        margin-top: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 70px;
    }
}

.fontStylingResolved {
    float: left;
    font-size: 30rem;
    margin-top: 25px;
    background: -webkit-linear-gradient(270deg, rgba(34, 34, 34, 1) 40%, rgba(5, 5, 5, 0.5) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 100px) {
    .fontStylingResolved {
        font-size: 2rem;
        margin-bottom: 180px;
        margin-top: 105px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-left: -140px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .fontStylingResolved {
        font-size: 8rem;
        margin-top: 65px;
        margin-bottom: 80px;
        padding-left: 10px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}


@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .fontStylingResolved {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 3px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .fontStylingResolved {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 7px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .fontStylingResolved {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 12px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}


@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .fontStylingResolved {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 17px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .fontStylingResolved {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -17.8vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .fontStylingResolved {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -16.5vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .fontStylingResolved {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -14vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .fontStylingResolved {
        font-size: 8rem;
        padding-left: 22px;
        margin-top: 70px;
        margin-bottom: 70px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .fontStylingResolved {
        font-size: 14.5rem;
        margin-left: -6vw;
        margin-top: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 70px;
    }
}

.searchstyledriver {
    margin-top: 166px;
    cursor: pointer;
    margin-left: 24vw;
    width: 100%;
    transition: filter 0.3s ease;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .searchstyledriver {
        width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .searchstyledriver {
        width: 500%;
        margin-left: 17vw;
        margin-top: 210px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .searchstyledriver {
        width: 300%;
        margin-left: 16vw;
        margin-top: 142px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .searchstyledriver {
        width: 300%;
        margin-left: 15.4vw;
        margin-top: 140px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .searchstyledriver {
        width: 500%;
        margin-left: 5vw;
        margin-top: 85px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .searchstyledriver {
        width: 500%;
        margin-left: 3vw;
        margin-top: 86px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .searchstyledriver {
        width: 320%;
        margin-left: 24vw;
        margin-top: 170px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .searchstyledriver {
        width: 150%;
        margin-left: 17.5vw;
        margin-top: 230px;
    }
}

.magnifyIconMenu {
    height: 38px;
    width: 38px;
    margin-top: -16px;
    cursor: pointer;
    margin-left: 4.5vw;
    transition: filter 0.3s ease;
}

@media screen and (min-width: 100px) {
    .magnifyIconMenu {
        margin-left: 5vw;
        margin-top: 76px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .magnifyIconMenu {
        margin-left: 22vw;
        margin-top: 200px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .magnifyIconMenu {
        margin-left: 22vw;
        margin-top: 106px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .magnifyIconMenu {
        margin-left: 22vw;
        margin-top: 106px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
    .magnifyIconMenu {
        margin-left: 15vw;
        margin-top: 52px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .magnifyIconMenu {
        margin-left: 28vw;
        margin-top: 156px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .magnifyIconMenu {
        margin-left: 20vw;
        margin-top: 196px;
    }
}

.magnifyIconUnassignedCases {
    height: 38px;
    width: 38px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-left: 34.2vw;
    margin-top: 100px;
    margin-bottom: -20px;
}

@media screen and (min-width: 100px) {
    .magnifyIconUnassignedCases {
        margin-left: -50px;
        margin-top: 105px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .magnifyIconUnassignedCases {
        /* margin-top: 225px; */
        margin-left: 34.5vw;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .magnifyIconUnassignedCases {
        margin-left: 150px;
        margin-top: 194px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .magnifyIconUnassignedCases {
        margin-left: 150px;
        margin-top: 145px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .magnifyIconUnassignedCases {
        margin-left: 82px;
        margin-top: 25px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .magnifyIconUnassignedCases {
        margin-left: 75px;
        margin-top: 90px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .magnifyIconUnassignedCases {
        margin-left: -32px;
        margin-top: -58px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1075px) {
    .magnifyIconUnassignedCases {
        margin-left: 2px;
        margin-top: -32px;
    }
}

@media only screen and (min-width: 1076px) and (max-width: 1110px) {
    .magnifyIconUnassignedCases {
        margin-left: 2px;
        margin-top: -40px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .magnifyIconUnassignedCases {
        margin-left: 2px;
        margin-top: 157px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .magnifyIconUnassignedCases {
        margin-top: 37px;
        margin-left: 12.82vw;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .magnifyIconUnassignedCases {
        margin-top: 50px;
        margin-left: 19vw;
        margin-bottom: 120px;
    }
}

.magnifyIconAssignedCases {
    height: 38px;
    width: 38px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-left: 36vw;
    margin-top: 100px;
    margin-bottom: -20px;
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .magnifyIconAssignedCases {
        /* margin-top: 225px; */
        margin-left: 37.6vw;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .magnifyIconAssignedCases {
        margin-left: 220px;
        margin-top: 145px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .magnifyIconAssignedCases {
        margin-left: 200px;
        margin-top: 192px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .magnifyIconAssignedCases {
        margin-left: 148px;
        margin-top: 285px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .magnifyIconAssignedCases {
        margin-left: 125px;
        margin-top: 115px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .magnifyIconAssignedCases {
        margin-left: -55px;
        margin-top: -58px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .magnifyIconAssignedCases {
        margin-left: -20px;
        margin-top: -58px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .magnifyIconAssignedCases {
        margin-left: 60px;
        margin-top: 162px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .magnifyIconAssignedCases {
        margin-left: 72px;
        margin-top: -28px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .magnifyIconAssignedCases {
        margin-top: 20px;
        margin-left: 18.82vw;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .magnifyIconAssignedCases {
        margin-top: 105px;
        margin-left: 21.5vw;
        margin-bottom: 100px;
    }
}

.magnifyIconResolvedCases {
    height: 38px;
    width: 38px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-left: 36vw;
    margin-top: -30px;
    margin-bottom: -20px;
}


.dotcircle {
    height: 31px;
    width: 31px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: 185px;
    margin-left: 13vw;
    /* margin-top: -3px; */
    /* margin-right: -1px; */
}

@media screen and (min-width: 100px) {
    .dotcircle {
        margin-left: -50px;
        margin-top: 105px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .dotcircle {
        margin-top: 225px;
        margin-left: 12.82vw;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .dotcircle {
        margin-left: 148px;
        margin-top: 135px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .dotcircle {
        margin-left: 125px;
        margin-top: 135px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .dotcircle {
        margin-left: -25px;
        margin-top: 88px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .dotcircle {
        margin-left: 2px;
        margin-top: 104px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .dotcircle {
        margin-top: 180px;
        margin-left: 12.82vw;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .dotcircle {
        margin-top: 225px;
        margin-left: 11.53vw;
    }
}

#tablecard {
    background-color: transparent;
    width: 100%;
    border-radius: 15px;

}

@media screen and (min-width: 100px) {
    #tablecard {
        margin-left: 63px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    #tablecard {
        margin-left: -10px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    #tablecard {
        margin-left: 80px;
        padding-right: 270px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    #tablecard {
        margin-left: 80px;
        padding-right: 150px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
    #tablecard {
        margin-left: 120px;
        padding-right: 120px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    #tablecard {
        margin-left: 0px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    #tablecard {
        margin-left: -130px;
        padding-right: 120px;

    }
}

/* To style RSA page layout */

#tablecardRSA {
    background-color: transparent;
    width: 100%;
    border-radius: 15px;

}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    #tablecardRSA {
        margin-left: -10px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    #tablecardRSA {
        margin-left: 80px;
        padding-right: 270px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    #tablecardRSA {
        margin-left: 80px;
        padding-right: 270px;
        margin-top: -30px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    #tablecardRSA {
        margin-top: -40px;
        margin-left: 80px;
        padding-right: 150px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    #tablecardRSA {
        margin-left: 80px;
        padding-right: 150px;
        margin-top: -40px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
    #tablecardRSA {
        margin-top: -30px;
        margin-left: 80px;
        padding-right: 150px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 1872px) {
    #tablecardRSA {
        margin-left: -65px;
    }
}

@media screen and (min-width: 1873px) and (max-width: 2600px) {
    #tablecardRSA {
        margin-left: -80px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    #tablecardRSA {
        margin-left: -170px;
    }
}

.rowheight {
    margin-top: 0px;
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
    .rowheight {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .rowheight {
        margin-top: 0px;
        margin-left: -60px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .rowheight {
        margin-top: 30px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .rowheight {
        margin-top: -40px;
        margin-left: -300px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .rowheight {
        margin-top: 45px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .rowheight {
        margin-top: 45px;
        margin-left: -450px;
    }
}

.rowheightOpenCases {
    margin-top: 0px;
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
    .rowheightOpenCases {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .rowheightOpenCases {
        margin-top: 0px;
        margin-left: -60px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .rowheightOpenCases {
        margin-top: 0px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .rowheightOpenCases {
        margin-top: -40px;
        margin-left: -300px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .rowheightOpenCases {
        margin-top: 45px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .rowheightOpenCases {
        margin-top: 45px;
        margin-left: -450px;
    }
}

.magnifyIcon {
    height: 28px;
    width: 28px;
    cursor: pointer;
    transition: filter 0.3s ease;
    /* margin-top: -3px; */
    /* margin-right: -1px; */
}

.closebutton {
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: filter 0.3s ease;
    /* margin-top: -3px; */
    /* margin-right: -1px; */
}

.dropdownSelect {
    width: 90%;
    z-index: 20;
    margin-left: 20vw;
    margin-top: 200px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .dropdownSelect {
        width: 450%;
        margin-left: -17vw;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1111px) {
    .dropdownSelect {
        width: 300%;
        margin-left: -6.1vw;
        margin-top: 78px;
    }
}

@media only screen and (min-width: 1112px) and (max-width: 1400px) {
    .dropdownSelect {
        width: 250%;
        margin-left: -2vw;
        margin-top: 135px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .dropdownSelect {
        margin-top: 0px;
        margin-left: -60px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .dropdownSelect {
        width: 190%;
        margin-left: 6.5vw;
        margin-top: 110px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .dropdownSelect {
        z-index: 20;
        margin-left: 22vw;
        margin-top: 200px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .dropdownSelect {
        width: 150%;
        z-index: 20;
        margin-left: 5vw;
        margin-top: 116px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .dropdownSelect {
        width: 120%;
        z-index: 20;
        margin-left: 12vw;
        margin-top: 191px;
    }
}

.dropdownSelectAssigned {
    width: 90%;
    z-index: 20;
    margin-left: 20vw;
    margin-top: 200px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .dropdownSelectAssigned {
        width: 450%;
        margin-left: -17vw;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1111px) {
    .dropdownSelectAssigned {
        width: 300%;
        margin-left: -6.1vw;
        margin-top: 78px;
    }
}

@media only screen and (min-width: 1112px) and (max-width: 1400px) {
    .dropdownSelectAssigned {
        width: 250%;
        margin-left: -2vw;
        margin-top: 135px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .dropdownSelectAssigned {
        margin-top: 0px;
        margin-left: -60px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .dropdownSelectAssigned {
        width: 190%;
        margin-left: 6.5vw;
        margin-top: 135px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .dropdownSelectAssigned {
        z-index: 20;
        margin-left: 22vw;
        margin-top: 200px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .dropdownSelectAssigned {
        width: 150%;
        z-index: 20;
        margin-left: 5vw;
        margin-top: 116px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .dropdownSelectAssigned {
        width: 120%;
        z-index: 20;
        margin-left: 12vw;
        margin-top: 191px;
    }
}


.dropdownSelectUnassigned {
    width: 90%;
    z-index: 200;
    margin-left: 17vw;
    margin-top: 200px;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .dropdownSelectUnassigned {
        width: 450%;
        margin-left: -13vw;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .dropdownSelectUnassigned {
        width: 450%;
        margin-left: -17vw;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1111px) {
    .dropdownSelectUnassigned {
        width: 300%;
        margin-left: -14vw;
        margin-top: 72px;
    }
}
@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .dropdownSelectUnassigned {
        width: 300%;
        margin-left: -12vw;
        margin-top: 72px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .dropdownSelectUnassigned {
        width: 250%;
        margin-left: -6vw;
        margin-top: 125px;
    }
}

@media only screen and (min-width: 1112px) and (max-width: 1280px) {
    .dropdownSelectUnassigned {
        width: 250%;
        margin-left: -6vw;
        margin-top: 110px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .dropdownSelectUnassigned {
        margin-top: 0px;
        margin-left: -60px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .dropdownSelectUnassigned {
        width: 200%;
        margin-left: 0.7vw;
        margin-top: 135px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .dropdownSelectUnassigned {
        width: 200%;
        margin-left: 2.8vw;
        margin-top: 135px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .dropdownSelectUnassigned {
        z-index: 20;
        margin-left: 19.5vw;
        margin-top: 203px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .dropdownSelectUnassigned {
        width: 170%;
        z-index: 20;
        margin-left: -5vw;
        margin-top: 135px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .dropdownSelectUnassigned {
        width: 100%;
        z-index: 20;
        margin-left: 11vw;
        margin-top: 140px;
    }
}

.searchStyleUnassignedCases {
    margin-top: 110px;
    cursor: pointer;
    margin-left: 29vw;
    width: 480%;
    margin-bottom: -25px;
    transition: filter 0.3s ease;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .searchStyleUnassignedCases {
        width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .searchStyleUnassignedCases {
        width: 440%;
        margin-left: 30vw;
        margin-top: 112px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .searchStyleUnassignedCases {
        width: 780%;
        margin-left: 5vw;
        margin-top: 155px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .searchStyleUnassignedCases {
        width: 780%;
        margin-left: 5vw;
        margin-top: 28px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .searchStyleUnassignedCases {
        width: 300%;
        margin-left: 15.4vw;
        margin-top: 140px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .searchStyleUnassignedCases {
        width: 1700%;
        margin-left: -13vw;
        margin-top: -35px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .searchStyleUnassignedCases {
        width: 1200%;
        margin-left: -6.5vw;
        margin-top: -20px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .searchStyleUnassignedCases {
        width: 950%;
        margin-left: -0.7vw;
        margin-top: 33px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1280px) {
    .searchStyleUnassignedCases {
        width: 250%;
        margin-left: -6vw;
        margin-top: 110px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .searchStyleUnassignedCases {
        width: 720%;
        margin-left: 8.8vw;
        margin-top: 45px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .searchStyleUnassignedCases {
        width: 720%;
        margin-left: 14.2vw;
        margin-top: 55px;
        margin-bottom: 150px;
    }
}

.searchStyleAssignedCases {
    margin-top: 110px;
    cursor: pointer;
    margin-left: 31.5vw;
    width: 450%;
    margin-bottom: -25px;
    transition: filter 0.3s ease;
    z-index: 20;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .searchStyleAssignedCases {
        width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .searchStyleAssignedCases {
        width: 440%;
        margin-left: 33vw;
        margin-top: 112px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .searchStyleAssignedCases {
        width: 780%;
        margin-left: 9vw;
        margin-top: 155px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .searchStyleAssignedCases {
        width: 780%;
        margin-left: 9vw;
        margin-top: 200px;
    }
}

/* @media only screen and (min-width: 1111px) and (max-width: 1280px) {
    .searchStyleAssignedCases {
        width: 300%;
        margin-left: 15.4vw;
        margin-top: 200px;
    }
} */

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .searchStyleAssignedCases {
        width: 1700%;
        margin-left: -13vw;
        margin-top: -35px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .searchStyleAssignedCases {
        width: 1200%;
        margin-left: -6.5vw;
        margin-top: -20px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .searchStyleAssignedCases {
        width: 950%;
        margin-left: 4.2vw;
        margin-top: 42px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1280px) {
    .searchStyleAssignedCases {
        width: 1050%;
        margin-left: 4vw;
        margin-top: 290px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .searchStyleAssignedCases {
        width: 750%;
        margin-left: 14.5vw;
        margin-top: 27px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .searchStyleAssignedCases {
        width: 720%;
        margin-left: 16.5vw;
        margin-top: 108px;
        margin-bottom: 118px;
    }
}

.searchStyleResolvedCases {
    margin-top: -20px;
    cursor: pointer;
    margin-left: 31.5vw;
    width: 100%;
    margin-bottom: -25px;
    transition: filter 0.3s ease;
}


.headerStyleMyOpenCases {
    margin-left: 5.55vw;
    margin-bottom: -140px;
    margin-top: -10px;
    margin-right: 20px;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .headerStyleMyOpenCases {
        /* width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px; */
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .headerStyleMyOpenCases {
        margin-top: -20px;

        /* width: 220%;
        margin-left: 24vw;
        margin-top: 192px; */
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .headerStyleMyOpenCases {
        margin-left: 5.55vw;
        margin-bottom: -140px;
        margin-top: -20px;
        margin-right: -54px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .headerStyleMyOpenCases {
        /* width: 300%;
        margin-left: 15.4vw;
        margin-top: 140px; */
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .headerStyleMyOpenCases {
        margin-left: 5.55vw;
        /* margin-bottom: -140px; */
        /* margin-top: -10px; */
        /* margin-right: -54px; */
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .headerStyleMyOpenCases {
        /* width: 500%;
        margin-left: 3vw;
        margin-top: 86px; */
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .headerStyleMyOpenCases {
        /* width: 320%;
        margin-left: 24vw;
        margin-top: 170px;     */
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .headerStyleMyOpenCases {
        /* width: 150%;
        margin-left: 17.5vw;
        margin-top: 230px;  */
    }
}

.paginationMyOpenCases {
    margin: -40px 0 0 0px;
    display: flex;
    font-size: 14px;
    color: #fff;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .paginationMyOpenCases {
        margin-top: -10px;
        margin-left: -100px;
        display: flex;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1110px) {
    .paginationMyOpenCases {
        margin-top: -120px;
        margin-left: -103px;
        display: flex;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1024px) {
    .paginationMyOpenCases {
        margin-top: 10px;
        margin-left: -100px;
        display: flex;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .paginationMyOpenCases {
        margin-top: 55px;
        margin-left: -100px;
        /* font-size: 20px; */
        display: flex;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .paginationMyOpenCases {
        margin-top: 5px;
        margin-left: -100px;
        /* font-size: 20px; */
        display: flex;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .paginationMyOpenCases {
        margin: 50px 0 0 -95px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .paginationMyOpenCases {
        margin: -270px 0px 0 -98px;

    }
}

@media screen and (min-width: 1801px) and (max-width: 2500px) {
    .paginationMyOpenCases {
        margin-top: 5px;
        margin-left: -100px;
        /* font-size: 20px; */
        display: flex;
    }
}

@media screen and (min-width: 2501px) and (max-width: 4500px) {
    .paginationMyOpenCases {
        margin-top: 300px;
        margin-left: -1280px;
        /* font-size: 20px; */
        display: flex;
    }
}

.paginationUnassignedCases {
    margin: 20px 10px 0 0px;
    display: flex;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .paginationUnassignedCases {
        margin-top: 30px;
        margin-left: -70px;
        display: flex;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .paginationUnassignedCases {
        margin-top: 30px;
        margin-left: -72px;
        display: flex;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .paginationUnassignedCases {
        margin-top: 30px;
        margin-left: -65px;
        display: flex;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .paginationUnassignedCases {
        margin-top: 5px;
        margin-left: 35px;
        display: flex;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .paginationUnassignedCases {
        margin-top: 5px;
        margin-left: 9px;
        display: flex;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .paginationUnassignedCases {
        margin-top: 20px;
        margin-left: 1px;
        display: flex;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .paginationUnassignedCases {
        margin: 70px 10px 0 -5px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .paginationUnassignedCases {
        margin-top: 30px;
        margin-left: -6px;
        display: flex;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .paginationUnassignedCases {
        margin-top: 550px;
        margin-left: -84vw;
        display: flex;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4500px) {
    .paginationUnassignedCases {
        margin-top: 520px;
        margin-left: -15.5vw;
        display: flex;
        /* font-size: 100px; */
    }
}


.paginationAssignedCases {
    margin: 20px 10px 0 0px;
    display: flex;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .paginationAssignedCases {
        margin-top: 30px;
        margin-left: -59px;
        display: flex;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .paginationAssignedCases {
        margin-top: 30px;
        margin-left: -57px;
        display: flex;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .paginationAssignedCases {
        margin-top: 30px;
        margin-left: -57px;
        display: flex;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .paginationAssignedCases {
        margin-top: 5px;
        margin-left: 9px;
        display: flex;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .paginationAssignedCases {
        margin-top: 40px;
        margin-left: -10px;
        display: flex;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .paginationAssignedCases {
        margin-top: 20px;
        margin-left: -12px;
        display: flex;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .paginationAssignedCases {
        margin: 30px 10px 0 -15px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .paginationAssignedCases {
        margin-top: 20px;
        margin-left: -16px;
        display: flex;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .paginationAssignedCases {
        /* margin-top: 550px; */
        /* margin-left: 42.2vw;
        display: flex; */

        margin-top: 5px;
        margin-left: 15px;
        display: flex;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4500px) {
    .paginationAssignedCases {
        margin-top: -15px;
        margin-left: 40px;
        display: flex;
    }
}

.paginationResolvedCases {
    margin: 20px 10px 0 0px;
    display: flex;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .paginationResolvedCases {
        margin-top: 30px;
        margin-left: -59px;
        display: flex;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .paginationResolvedCases {
        margin-top: 30px;
        margin-left: -57px;
        display: flex;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .paginationResolvedCases {
        margin-top: 30px;
        margin-left: -57px;
        display: flex;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .paginationResolvedCases {
        margin-top: 5px;
        margin-left: 9px;
        display: flex;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .paginationResolvedCases {
        margin-top: 15px;
        margin-left: -12px;
        display: flex;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .paginationResolvedCases {
        margin-top: 20px;
        margin-left: -13px;
        display: flex;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .paginationResolvedCases {
        margin: 30px 10px 0 -15px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .paginationResolvedCases {
        margin-top: 20px;
        margin-left: -16px;
        display: flex;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .paginationResolvedCases {
        margin-top: 5px;
        margin-left: 30px;
        display: flex;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4500px) {
    .paginationResolvedCases {
        margin-top: -15px;
        margin-left: 40px;
        display: flex;
    }
}

.downloadSelectStyle {
    margin-top: 5px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .downloadSelectStyle {
        /* margin-top: 5px; */
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .downloadSelectStyle {
        margin-top: 30px;
        margin-left: -35px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .downloadSelectStyle {
        margin-top: -58px;
        margin-left: -112px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .downloadSelectStyle {
        margin-top: 5px;
        margin-left: 65px;
    }
}


.downloadSelectUnassigned {
    margin-top: -115px;
    margin-left: -31px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .downloadSelectUnassigned {
        margin-top: -115px;
        margin-left: -100px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .downloadSelectUnassigned {
        margin-top: 82px;
        margin-left: -120px;
    }
}


@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .downloadSelectUnassigned {
        margin-top: -120px;
        margin-left: -120px;
    }
}
@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .downloadSelectUnassigned {
        margin-top: -40px;
        margin-left: -210px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .downloadSelectUnassigned {
        margin-top: 255px;
        margin-left: -190px;
        margin-bottom: -220px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .downloadSelectUnassigned {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
    .downloadSelectUnassigned {
        margin-top: 88px;
        margin-left: -185px;
        margin-bottom: 80px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .downloadSelectUnassigned {
        margin-top: 40px;
        margin-left: -185px;
        margin-bottom: 80px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .downloadSelectUnassigned {
        /* margin-top: 5px; */
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .downloadSelectUnassigned {
        margin-top: -138px;
        margin-left: -144px;
        margin-bottom: 130px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .downloadSelectUnassigned {
        margin-top: -165px;
        margin-left: -155px;
    }
}

.downloadSelectAssigned {
    margin-top: -115px;
    margin-left: -31px;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .downloadSelectAssigned {
        margin-top: -115px;
        margin-left: -140px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .downloadSelectAssigned {
        margin-top: -115px;
        margin-left: -120px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .downloadSelectAssigned {
        margin-top: 87px;
        margin-left: -120px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .downloadSelectAssigned {
        margin-top: -117px;
        margin-left: -120px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .downloadSelectAssigned {
        margin-top: 10px;
        margin-left: -210px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .downloadSelectAssigned {
        margin-top: 177px;
        margin-left: -188px;
        /* margin-bottom: -603px; */
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .downloadSelectAssigned {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .downloadSelectAssigned {
        margin-top: 85px;
        margin-left: -185px;
        margin-bottom: 85px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .downloadSelectAssigned {
        margin-top: 40px;
        margin-left: -185px;
        margin-bottom: 80px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .downloadSelectAssigned {
        /* margin-top: 5px; */
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .downloadSelectAssigned {
        margin-top: -157px;
        margin-left: -144px;
        margin-bottom: 130px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .downloadSelectAssigned {
        margin-top: -118px;
        margin-left: -150px;
    }
}

/* to style details page in Assigned, Unassigned and Resolved cases table */

.detailsOpenCasestyle {
    position: relative;
    width: 101%;
    margin-top: 120px;
    background-color: transparent;
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .detailsOpenCasestyle {
        margin-top: 65px;
        width: 99%;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .detailsOpenCasestyle {
        margin-top: 80px;
        width: 99%;
        height: 640px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .detailsOpenCasestyle {
        margin-top: 80px;
        width: 99%;
        height: 610px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1275px) {
    .detailsOpenCasestyle {
        margin-top: 80px;
        width: 99%;
        height: 510px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .detailsOpenCasestyle {
        margin-top: 80px;
        width: 99%;
        height: 660px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.detailsAssignedCasestyle {
    position: relative;
    width: 122%;
    margin-top: -30px;
    background-color: transparent;
    /* margin-right: 300px; */
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .detailsAssignedCasestyle {
        margin-top: 60px;
        width: 116%;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .detailsAssignedCasestyle {
        margin-top: 120px;
        width: 111%;
        height: 630px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .detailsAssignedCasestyle {
        margin-top: -60px;
        width: 100%;
        height: 610px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.detailsRowStyle {
    padding: 10px;
    margin-top: -15px;
    margin-right: -28px;
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .detailsRowStyle {
        margin-top: 0px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .detailsRowStyle {
        margin-top: 0px;
    }
}

.searchfieldStyleWhoisConnected {
    width: 680%;
    margin-top: -120px;
    margin-left: -109px;
}

@media only screen and (min-width: 1451px) and (max-width: 1600px) {
    .searchfieldStyleWhoisConnected {
        width: 120%;
        margin-left: -122px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .searchfieldStyleWhoisConnected {
        width: 120%;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .searchfieldStyleWhoisConnected {
        width: 97%;
        margin-left: 53px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1280px) {
    .searchfieldStyleWhoisConnected {
        width: 145%;
        margin-left: -115px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .searchfieldStyleWhoisConnected {
        width: 175%;
        margin-left: -115px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .searchfieldStyleWhoisConnected {
        width: 115%;
        margin-left: -120px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .searchfieldStyleWhoisConnected {
        margin-left: -130px;
        margin-top: -110px;
        /* margin-bottom: 900px; */
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .searchfieldStyleWhoisConnected {
        margin-left: -130px;
        margin-top: -110px;
    }
}

.mapheight {
    height: 610px;
    margin-top: -20px;
    margin-bottom: -25px;
    border-radius: 30px;
    margin-left: -20px;
    margin-right: -15px;
    /* box-shadow: 3px 5px 6px #000000; */
}

@media screen and (min-width: 1800px) and (max-width: 1873px) {
    .mapheight {
        height:650px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .mapheight {
        margin-top: -15px;
        height: 520px;
        margin-left: -30px;
        margin-right: -16px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .mapheight {
        height: 470px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .mapheight {
        height: 630px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1280px) {
    .mapheight {
        height: 440px;
        margin-right: -12px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .mapheight {
        height: 350px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1100px) {
    .mapheight {
        height: 565px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
    .mapheight {
        height: 525px;
        /* margin-top: -55px; */
        margin-left: -27px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .mapheight {
        height: 750px;
        margin-left: -43px;
        margin-right: -40px;
        margin-top: -60px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .mapheight {
        height: 750px;
        margin-left: -43px;
        margin-right: -40px;
        margin-top: -85px;
    }
}

.mapheightSVT {
    height: 610px;
    margin-top: 110px;
    margin-bottom: -25px;
    border-radius: 30px;
    margin-left: 7px;
    margin-right: -55px;
    /* box-shadow: 3px 5px 6px #000000; */
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .mapheightSVT {
        margin-right: 1690px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .mapheightSVT {
        margin-top: 105px;
        height: 490px;
        margin-left: 10px;
        margin-right: 240px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .mapheightSVT {
        margin-top: 92px;
        height: 490px;
        margin-left: 25px;
        margin-right: 240px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .mapheightSVT {
        height: 630px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1280px) {
    .mapheightSVT {
        height: 450px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .mapheightSVT {
        height: 350px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1100px) {
    .mapheightSVT {
        margin-top: 94px;
        height: 565px;
        margin-left: -2px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
    .mapheightSVT {
        margin-top: 95px;
        height: 520px;
        margin-left: -6px;
        margin-right: -65px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .mapheightSVT {
        height: 748px;
        margin-left: -15px;
        margin-right: -75px;
        margin-top: 90px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .mapheightSVT {
        height: 650px;
        margin-bottom: 10px;
        margin-left: -15px;
        margin-right: -70px;
        margin-top: 95px;
    }
}

.mapheightDetails {
    height: 610px;
    margin-top: -20px;
    margin-bottom: -25px;
    border-radius: 30px;
    margin-left: -20px;
    margin-right: -15px;
    /* box-shadow: 3px 5px 6px #000000; */
}

@media screen and (min-width: 1800px) and (max-width: 1873px) {
    .mapheightDetails {
        height:650px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .mapheightDetails {
        margin-top: -15px;
        height: 520px;
        margin-left: -30px;
        margin-right: -16px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .mapheightDetails {
        height: 470px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .mapheightDetails {
        height: 630px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1280px) {
    .mapheightDetails {
        height: 440px;
        margin-right: -12px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .mapheightDetails {
        height: 350px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1100px) {
    .mapheightDetails {
        height: 565px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
    .mapheightDetails {
        height: 525px;
        margin-top: -36px;
        margin-left: -27px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .mapheightDetails {
        height: 750px;
        margin-left: -43px;
        margin-right: -40px;
        margin-top: -45px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .mapheightDetails {
        height: 690px;
        margin-left: -35px;
        margin-right: -35px;
        margin-top: -35px;
    }
}

.detailspagestyleUndefined {
    padding: 10px;
    margin-top: -50px;
    height: 750;
    margin-left: -15px;
    margin-right: -98px;
    overflow: hidden;
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .detailspagestyleUndefined {
        margin-left: -55px;
        margin-right: -50px;
        margin-top: 20px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .detailspagestyleUndefined {
        margin-left: -55px;
        margin-right: -50px;
        margin-top: 20px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .detailspagestyleUndefined {
        margin-left: -33px;
        margin-right: -272px;
        margin-top: 20px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .detailspagestyleUndefined {
        margin-left: -35px;
        margin-right: 10px;
        margin-top: -2px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .detailspagestyleUndefined {
        margin-left: 3px;
        margin-right: -1px;
        margin-top: -2px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .detailspagestyleUndefined {
        margin-left: 3px;
        margin-right: -1px;
        margin-top: -2px;
    }
}

@media only screen and (min-width: 1273px) and (max-width: 1280px) {
    .detailspagestyleUndefined {
        margin-left: 3px;
        margin-right: -1px;
        margin-top: -13px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1272px) {
    .detailspagestyleUndefined {
        margin-left: 3px;
        margin-right: -1px;
        margin-top: -3px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .detailspagestyleUndefined {
        margin-left: -46px;
        margin-right: -30px;
        margin-top: -20px;
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1075px) {
    .detailspagestyleUndefined {
        margin-left: -53px;
        margin-right: -35px;
        margin-top: -5px;
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1076px) and (max-width: 1110px) {
    .detailspagestyleUndefined {
        margin-left: 40px;
        margin-right: -45px;
        margin-top: -130px;
        /* overflow: auto; */
        overflow-x: hidden;
        /* height: 00px; */
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .detailspagestyleUndefined {
        margin-left: -35px;
        margin-right: -1px;
        margin-top: 10px;
        overflow: auto;
        padding-left: 60px;
        overflow-x: hidden;
        height: 920px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .detailspagestyleUndefined {
        margin-left: -35px;
        margin-right: -1px;
        margin-top: 10px;
        overflow: auto;
        padding-left: 60px;
        overflow-x: hidden;
        height: 890px;
    }
}

/* To style details tab in RSA component */
.detailsRSA {
    padding: 10px;
    margin-top: -50px;
    height: 750;
    margin-left: -15px;
    margin-right: -98px;
    overflow: hidden;
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .detailsRSA {
        margin-left: -955px;
        margin-right: 550px;
        margin-top: 20px;
    }
}

@media screen and (min-width: 1872px) and (max-width: 1900px) {
    .detailsRSA {
        margin-left: -65px;
        margin-right: -320px;
        margin-top: 20px;
    }
}

@media screen and (min-width: 1901px) and (max-width: 2600px) {
    .detailsRSA {
        margin-left: -85px;
        margin-right: -320px;
        margin-top: 20px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .detailsRSA {
        margin-left: -33px;
        margin-right: -272px;
        margin-top: 20px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .detailsRSA {
        margin-left: -35px;
        margin-right: 10px;
        margin-top: -2px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .detailsRSA {
        margin-left: -11px;
        margin-right: 10px;
        margin-top: -2px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .detailsRSA {
        margin-left: 3px;
        margin-right: -1px;
        margin-top: -2px;
    }
}

@media only screen and (min-width: 1273px) and (max-width: 1280px) {
    .detailsRSA {
        margin-left: -33px;
        margin-right: -240px;
        margin-top: 13px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1272px) {
    .detailsRSA {
        margin-left: -33px;
        margin-right: -240px;
        margin-top: 13px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .detailsRSA {
        margin-left: -40px;
        margin-right: -290px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1075px) {
    .detailsRSA {
        margin-left: -40px;
        margin-right: -270px;
        margin-top: -130px;

    }
}

@media only screen and (min-width: 1076px) and (max-width: 1110px) {
    .detailsRSA {
        margin-left: 40px;
        margin-right: -45px;
        margin-top: -130px;
        /* overflow: auto; */
        overflow-x: hidden;
        /* height: 00px; */
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .detailsRSA {
        margin-left: -70px;
        margin-right: -40px;
        /* margin-top: 10px; */
        overflow: auto;
        padding-left: 60px;
        overflow-x: hidden;
        height: 720px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .detailsRSA {
        margin-left: -70px;
        margin-right: -40px;
        overflow: auto;
        padding-left: 60px;
        overflow-x: hidden;
        height: 700px;
    }
}

#CardBodyCardAllowanceDetailsCarCard2 {
    /* background-image: linear-gradient(0deg, #000000 35%, #181818); */
    background-color: #181818;
    border-radius: 15px;
    height: 610px;
    margin-top: 0px;
}

@media screen and (min-width: 1872px) and (max-width: 1900px) {
    #CardBodyCardAllowanceDetailsCarCard2 {
        height: 651px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    #CardBodyCardAllowanceDetailsCarCard2 {
        margin-top: 5px;
        height: 521px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    #CardBodyCardAllowanceDetailsCarCard2 {
        margin-top: 5px;
        height: 465px;
    }
}

@media screen and (min-width: 1276px) and (max-width: 1280px) {
    #CardBodyCardAllowanceDetailsCarCard2 {
        height: 441px;
    }
}

@media screen and (min-width: 1111px) and (max-width: 1275px) {
    #CardBodyCardAllowanceDetailsCarCard2 {
        height: 350px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1075px) {
    #CardBodyCardAllowanceDetailsCarCard2 {
        height: 564px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    #CardBodyCardAllowanceDetailsCarCard2 {
        height: 528px;
    }
}

#CardBodyCardAllowanceDetailsCarCard {
    /* background-image: linear-gradient(0deg, #000000 35%, #181818); */
    background-color: #181818;
    border-radius: 15px;
    height: 300px;
    margin-top: 0px;
}


@media screen and (min-width: 1451px) and (max-width: 1600px) {
    #CardBodyCardAllowanceDetailsCarCard {
        margin-top: 5px;
        height: 256px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    #CardBodyCardAllowanceDetailsCarCard {
        margin-top: 5px;
        height: 225px;
    }
}

@media screen and (min-width: 1276px) and (max-width: 1280px) {
    #CardBodyCardAllowanceDetailsCarCard {
        height: 215px;
    }
}

@media screen and (min-width: 1111px) and (max-width: 1275px) {
    #CardBodyCardAllowanceDetailsCarCard {
        height: 170px;
    }
}

@media screen and (min-width: 1872px) and (max-width: 1900px) {
    #CardBodyCardAllowanceDetailsCarCard {
        height: 320px;
    }
}

@media screen and (min-width: 1026px) and (max-width: 1075px) {
    #CardBodyCardAllowanceDetailsCarCard {
        height: 282px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1024px) {
    #CardBodyCardAllowanceDetailsCarCard {
        height: 260px;
    }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    #CardBodyCardAllowanceDetailsCarCard {
        height: 450px;
    }
}

#CardBodyCardAllowanceDetails {
    /* background-image: linear-gradient(0deg, #000000 15%, #181818); */
    background-color: #181818;
    border-radius: 15px;
    height: 15vh;
    margin-bottom: 10px;
    /* cursor: pointer; */
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    #CardBodyCardAllowanceDetails {
        height: 14.6vh;
    }
}

@media screen and (min-width: 1913px) and (max-width: 2600px) {
    #CardBodyCardAllowanceDetails {
        height: 15.45vh;
    }
}

@media screen and (min-width: 1900px) and (max-width: 1912px) {
    #CardBodyCardAllowanceDetails {
        height: 15.1vh;
    }
}

@media screen and (min-width: 1872px) and (max-width: 1900px) {
    #CardBodyCardAllowanceDetails {
        height: 14.76vh;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    #CardBodyCardAllowanceDetails {
        height: 14.82vh;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    #CardBodyCardAllowanceDetails {
        height: 14.6vh;
    }
}

@media screen and (min-width: 1111px) and (max-width: 1275px) {
    #CardBodyCardAllowanceDetails {
        height: 14vh;
    }
}

@media screen and (min-width: 1276px) and (max-width: 1280px) {
    #CardBodyCardAllowanceDetails {
        height: 14.25vh;
    }
}

@media screen and (min-width: 1026px) and (max-width: 1075px) {
    #CardBodyCardAllowanceDetails {
        height: 15.1vh;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1024px) {
    #CardBodyCardAllowanceDetails {
        height: 15.8vh;
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
    #CardBodyCardAllowanceDetails {
        height: 13.48vh;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
    #CardBodyCardAllowanceDetails {
        height: 14.15vh;
        margin-bottom: 10px;
    }
}

#CardRowStyle {
    margin-left: -240px;
    margin-right: 200px;
    margin-top: 0px;
    margin-bottom: 8px;
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    #CardRowStyle {
        margin-top: 5px;
    }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    #CardRowStyle {
        margin-left: -30px;
        margin-right: -30px;
    }
}

.carouselImage {
    margin-top: 40px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 35%;
}

@media only screen and (min-width: 1026px) and (max-width: 1075px) {
    .carouselImage {
        width: 95%;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .carouselImage {
        width: 85%;
    }
}

.rsaHeaderFontStyle {
    padding: 15px;
    font-size: 13px;
    color: #b3b3b3;
    text-transform: uppercase;
}

@media screen and (min-width: 1872px) and (max-width: 2500px) {
    .rsaHeaderFontStyle {
        font-size: 18px;
    }
}

.rsaHeaderFontStyle2 {
    /* padding: 15px; */
    padding-left: 15px;
    padding-top: 15px;
    font-size: 13px;
    color: #b3b3b3;
    text-transform: uppercase;
}

@media screen and (min-width: 1872px) and (max-width: 2500px) {
    .rsaHeaderFontStyle2 {
        font-size: 18px;
    }
}

.rsaBodyFontStyle {
    /* alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '28px', fontFamily: 'LynkcoTypeMedium', color: '#808080', marginTop: '3px' */
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 28px;
    color: #b3b3b3;
    /* margin-top: 3px; */
}

@media screen and (min-width: 1276px) and (max-width: 1280px) {
    .rsaBodyFontStyle {
        font-size: 25px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .rsaBodyFontStyle {
        font-size: 20px;
    }
}

/* To style Days to Service card in details tab in RSA page */
.rsaDaysToService {
    /* alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '28px', fontFamily: 'LynkcoTypeMedium', color: '#808080', marginTop: '3px' */
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 45px;
    color: #b3b3b3;
    /* margin-top: 3px; */
}

@media screen and (min-width: 1276px) and (max-width: 1280px) {
    .rsaDaysToService {
        font-size: 35px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .rsaDaysToService {
        font-size: 30px;
    }
}

.rsaBodyVehicleDetailsFontStyle {
    padding: 15px;
    font-size: 18px;
    color: #b3b3b3;
    /* text-transform: uppercase; */
    margin-top: -32px;
    margin-bottom: 17px;
}

@media screen and (min-width: 1872px) and (max-width: 1900px) {
    .rsaBodyVehicleDetailsFontStyle {
        margin-bottom: 10px;
        font-size: 20px;
    }
}

@media screen and (min-width: 1901px) and (max-width: 2500px) {
    .rsaBodyVehicleDetailsFontStyle {
        margin-bottom: 5px;
        font-size: 20px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .rsaBodyVehicleDetailsFontStyle {
        margin-bottom: 2px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .rsaBodyVehicleDetailsFontStyle {
        margin-bottom: -6px;
        font-size: 17px;
    }
}

@media screen and (min-width: 1276px) and (max-width: 1280px) {
    .rsaBodyVehicleDetailsFontStyle {
        margin-bottom: -4px;
        font-size: 14px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .rsaBodyVehicleDetailsFontStyle {
        margin-bottom: -18px;
        font-size: 14px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1100px) {
    .rsaBodyVehicleDetailsFontStyle {
        margin-bottom: 12px;
        font-size: 14px;
    }
}

#connectedDriverCard_One {
    background-color: #181818;
    margin-top: -20px;
    border-radius: 30px;
    overflow: auto;
    height: 180px;
}

@media screen and (min-width: 1801px) and (max-width: 2500px) {
    #connectedDriverCard_One {
        height: 150px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    #connectedDriverCard_One {
        height: 150px;
    }
}

@media screen and (min-width: 1276px) and (max-width: 1280px) {
    #connectedDriverCard_One {
        height: 140px;
    }
}

@media screen and (min-width: 1111px) and (max-width: 1275px) {
    #connectedDriverCard_One {
        height: 140px;
    }
}

#connectedDriverCard_two {
    background-color: #181818;
    margin-top: -15px;
    border-radius: 30px;
    overflow: auto;
    height: 250px;
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    #connectedDriverCard_two {
        height: 160px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2500px) {
    #connectedDriverCard_two {
        height: 240px;
    }
}

@media screen and (min-width: 1276px) and (max-width: 1280px) {
    #connectedDriverCard_two {
        height: 180px;
    }
}

@media screen and (min-width: 1111px) and (max-width: 1275px) {
    #connectedDriverCard_two {
        height: 180px;
    }
}

.mapCardRSADetails {
    margin-left: -30px;
    margin-top: -26px;
    margin-right: 40px;
}

@media screen and (min-width: 771px) and (max-width: 1000px) {
    .mapCardRSADetails {
        margin-left: -30px;
        margin-top: 30px;
        margin-right: 10px;
    }
}

@media screen and (min-width: 700px) and (max-width: 770px) {
    .mapCardRSADetails {
        margin-left: -30px;
        margin-top: 60px;
        margin-right: 10px;
    }
}

.carouselCardRSADetails {
    margin-left: -50px;
    margin-right: 190px;
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    .carouselCardRSADetails {
        margin-top: 16px;
        margin-right: -15px;
    }
}

.vehicleInfoCardRSADetails {
    margin-left: -214px;
    margin-right: 200px;
    margin-bottom: 8px;
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    .vehicleInfoCardRSADetails {
        margin-left: -64px;
        margin-right: -15px;
    }
}
  
  /* .ul1 {
    display: flex;
    gap: 0 2rem;
    margin: 0;
    padding: 0.5rem 1rem 0.3rem 1rem;
    position: relative;
    border-radius: 1.5rem;
    list-style: none;
    background-color: hsl(200, 50%, 30%);
    isolation: isolate;
  }
  
  .ul1:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: var(--left, 0%);
    top: 0;
    background-color: hsl(200, 50%, 50%);
    border-radius: 1.5rem;
    z-index: -1;
    transition: left 0.2s ease;
  }
  
  .ul1:has(#one:checked):after {
    --left: 0%;
  }
  
  .ul1:has(#two:checked):after {
    --left: 20%;
  }
  
  .ul1:has(#three:checked):after {
    --left: 40%;
  }
  
  .ul1:has(#four:checked):after {
    --left: 60%;
  }
  
  .ul1:has(#five:checked):after {
    --left: 80%;
  }
  
  input[type="radio"] {
    display: none;
  } */
  
  /* svg {
    height: 8vmin;
    fill: hsl(200, 50%, 80%);
    trasition: fill 0.2s ease;
  } */
  
  /* :checked + label svg {
    fill: hsl(200, 50%, 15%);
  }

  .body1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    min-height: 10vh;
    background-color:transparent;
    color: hsl(200, 50%, 70%);
    font-family: sans-serif;
  } */