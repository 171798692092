@font-face {
    /* font-family: "MyFont";
    src: url("src/shared/img/logo/font/LynkcoType-Regular.ttf") format("truetype"); */
}

/* .dropdown-menu-black {  
    color: white;
    border: 0px;
} */

.dropdown-menu-black {
    background-color: #000000;
    background-image: linear-gradient(135deg, #000000 20%, #1b1b1b 99%);
    border-radius: 10px;
    color: white;
    border: 0px;
}

.dropdown-item {
    color: white;
    border-radius: 2px;
}

.dropdown-item:hover {
    color: grey;
    background-image: linear-gradient(135deg, #000000 20%, #1b1b1b 99%);
}

.dropdown-item:focus,
.dropdown-item.active {
    color: black;
    background-color: black;
    outline: none;
}



#CardBodyAlCardcards {
    /* background: linear-gradient( -50deg, rgb(25, 25, 25) 5% 20%, rgb(20, 20, 20) 20% 45%, rgb(14, 14, 14) 40% 70%, rgba(10,10,10,1) 70% 90%, black 10% );
   */
    background-image: linear-gradient(135deg, #101010 20%, #373737 99%);
    border-bottom-left-radius: 10px;
    font-family: "MyFont";
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    padding: 10px;
    color: white;
}

/* .dot:hover {
    filter: brightness(50%);
} */

.dot {
    height: 33px;
    width: 33px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: -3px;
    /* margin-right: -1px; */
}

.icons {
    height: 44px;
    width: 44px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: -3px;
    /* margin-right: -1px; */
}

.button {
    height: 230px;
    width: 230px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: -3px;
    /* margin-right: -1px; */
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .button {
        height: 180px;
        width: 180px;
        margin-top: -23px;
    }
}

.buttonMedium {
    height: 260px;
    width: 260px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: -3px;
    /* margin-right: -1px; */
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .buttonMedium {
        height: 200px;
        width: 200px;
        margin-top: -23px;
    }
}

.buttonShadow {
    height: 15px;
    width: 150px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: -3px;
    /* margin-right: -1px; */
}

.plus {
    height: 27px;
    width: 27px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: -11px;
    margin-left: -18px;
}

.plusIconMyOpenCases {
    height: 27px;
    width: 27px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: 210px;
    margin-left: 21.7vw;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .plusIconMyOpenCases {
        width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .plusIconMyOpenCases {
        /* width: 500%; */
        /* margin-left: 17vw;
        margin-top: 210px; */
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .plusIconMyOpenCases {
        margin-left: 13vw;
        margin-top: 120px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .plusIconMyOpenCases {
        width: 300%;
        margin-left: 10.4vw;
        margin-top: 145px;
    }
}

/* @media only screen and (min-width: 1001px) and (max-width: 1110px) {
.plusIconMyOpenCases {
        width: 300%;
        margin-left: 10.4vw;
        margin-top: 145px;
    }
} */

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .plusIconMyOpenCases {
        margin-left: -4vw;
        margin-top: 72px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .plusIconMyOpenCases {
        width: 500%;
        margin-left: -2vw;
        margin-top: 72px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .plusIconMyOpenCases {
        margin-left: 4vw;
        margin-top: 88px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .plusIconMyOpenCases {
        margin-left: 10vw;
        margin-top: 124px;    
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .plusIconMyOpenCases {
        width: 150%;
        margin-left: 16.3vw;
        margin-top: 210px; 
    }
}

.plusIconUnassignedCases {
    height: 27px;
    width: 27px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: 113px;
    margin-left: 23.7vw;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .plusIconUnassignedCases {
        margin-top: -35px;
        margin-left: -13.5vw;
        /* margin-bottom: -300px; */
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .plusIconUnassignedCases {
        margin-top: -35px;
        margin-left: -12vw;
        /* margin-bottom: -300px; */
    }
}

/* @media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .plusIconUnassignedCases {
        margin-top: -15px;
        margin-left: -5.5vw;
    }
} */

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .plusIconUnassignedCases {
        margin-top: 165px;
        margin-left: -5.5vw;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1075px) {
    .plusIconUnassignedCases {
        margin-top: -13px;
        margin-left: -5.7vw;
        /* margin-bottom: -300px; */
    }
}

@media only screen and (min-width: 1076px) and (max-width: 1275px) {
    .plusIconUnassignedCases {
        margin-top: 100px;
        margin-left: 1vw;
        /* margin-bottom: -300px; */
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .plusIconUnassignedCases {
        height: 27px;
        width: 27px;
        cursor: pointer;
        transition: filter 0.3s ease;
        margin-top: 20px;
        margin-left: -6vw;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .plusIconUnassignedCases {
        height: 27px;
        width: 27px;
        cursor: pointer;
        transition: filter 0.3s ease;
        margin-top: 202px;
        margin-left: 5vw;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .plusIconUnassignedCases {
        height: 27px;
        width: 27px;
        cursor: pointer;
        transition: filter 0.3s ease;
        margin-top: 153px;
        margin-left: 5vw;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .plusIconUnassignedCases {
        margin-top: 45px;
        margin-left: 10vw;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .plusIconUnassignedCases {
        margin-left: 13.5vw;
        margin-top: 60px;
    }
}

.edit {
    height: 25px;
    width: 25px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: -11px;
    margin-left: -18px;
}

.arrowRight {
    height: 22px;
    width: 22px;
    cursor: pointer;
    transition: filter 0.3s ease;
    /* margin-top: 2px; */
    margin-left: 15px;

}

.arrowLeft {
    height: 22px;
    width: 22px;
    cursor: pointer;
    transition: filter 0.3s ease;
    /* margin-top: 2px; */
    margin-left: -85px;
}

.check {
    height: 20px;
    width: 20px;
}

#Tabletitles {
    vertical-align: middle;
    text-align: center;
}

.imagecarview {
    height: 10%;
    width: 10%;
    display: block;
    margin: 0 auto;
}

#imagecarviewTr {
    display: inline;
    flex-wrap: wrap;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -100px;
}

.horizontal-line {
    border-bottom: 2px solid rgba(37, 37, 37, 1);
}