/*******************
customizer
*******************/
.customizer{
    background: $theme-light;
    position: fixed;
    height: 100%;
    width: 280px;
    top:0px;
    right: -280px;
    z-index: 100;
    box-shadow: $shadow;
    transition: 0.3s ease-in;
    .service-panel-toggle{
        background: $greenlynko;
        padding: 12px 20px;
        color:$white;
        position: absolute;
        top:30%;
        left:-57px; 
    }
    &.show-service-panel{
        right: 0px;
    } 
    .customizer-body{
        position: relative;
        height: 100%;
    }
    .customizer-tab{
        display: flex;
        .nav-item {
            width: 33.33%;
            text-align: center;
            .nav-link{
                padding: 15px 12px;
                color:$body-color;
                border-bottom: 3px solid transparent;
                &:hover, &.active{
                    border-bottom: 3px solid $themecolor;
                    color:$themecolor;
                }
            }
        }
    }
}
.custom-control-label::after, .custom-control-label::before{
    top:0.15rem;
}


.theme-color{
    padding:0px;
    margin: 0px;
    list-style:none;
    .theme-item{
        list-style: none;
        display: inline-block;
        margin-right: 5px;
        .theme-link{
            border-radius: 100%;
            width: 20px;
            height: 20px;
            display: block;
            &[data-logobg="skin1"], &[data-navbarbg="skin1"], &[data-sidebarbg="skin1"] {
                background: $skin1;
            }
            &[data-logobg="skin2"], &[data-navbarbg="skin2"], &[data-sidebarbg="skin2"]{
                background: $skin2;
            }
            &[data-logobg="skin3"], &[data-navbarbg="skin3"], &[data-sidebarbg="skin3"]{
                background: $skin3;
            }
            &[data-logobg="skin4"], &[data-navbarbg="skin4"], &[data-sidebarbg="skin4"]{
                background: $skin4;
            }
            &[data-logobg="skin5"], &[data-navbarbg="skin5"], &[data-sidebarbg="skin5"]{
                background: $skin5;
            }
            &[data-logobg="skin6"], &[data-navbarbg="skin6"], &[data-sidebarbg="skin6"]{
                background: $gray-200;
            }
        }
    }
}


// ......................... EasyDev Styling .......................

.customizer {
    position: fixed;
    top: 0;
    z-index: 102;
  
    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  
    .customizer__toggle {
  
      span {
  
        @include directify($directions) {
          #{directed('margin-right')}: 10px;
          #{directed('margin-left')}: 0px;
        }
  
        @include themify($themes) {
          color: themed('colorText');
        }
      }
    }
  }
  
  .customizer__btn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 350px;
    border: none;
    border-radius: 3px 0 0 3px;
    box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
    cursor: pointer;
    z-index: 102;
  
    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  
    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  }
  
  .customizer__btn-icon {
    width: 18px;
    height: 18px;
    animation: iconOpacity linear infinite 4s;
  }
  
  @keyframes iconOpacity {
  
    from {
      opacity: 1
    }
    50% {
      opacity: 0.3
    }
    to {
      opacity: 1
    }
  }
  
  .customizer__wrap {
    height: 100vh;
    width: 240px;
    box-shadow: none;
    padding: 20px;
    transform: translateX(100%);
    transition: transform 0.3s;
    position: fixed;
    top: 0;
    z-index: 102;
  
    &.customizer__wrap--open {
      transform: translateX(0);
      box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
    }
  
    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  
    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  }
  
  .customizer__title-wrap {
    padding-bottom: 20px;
    position: relative;
  
    @include directify($directions) {
      text-align: directed('left');
    }
  
    @include themify($themes) {
      border-bottom: 1px solid themed('colorBorder');
    }
  }
  
  .customizer__caption {
    color: $color-additional;
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 20px;
  
    @include directify($directions) {
      text-align: directed('left');
    }
  }
  
  .customizer__close-btn {
    position: absolute;
    top: 0;
    background: transparent;
    border: none;
    cursor: pointer;
  
    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  
    svg {
      fill: $color-additional;
      width: 14px;
      height: 14px;
      transition: 0.3s;
    }
  
    &:hover {
  
      svg {
        fill: $color-accent;
      }
    }
  }